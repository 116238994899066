/* eslint-disable prettier/prettier */
import React from "react";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import "./LineChart.css";
import { ContentContainer } from "../../Atoms/ContentContainer/ContentContainer";
import {
  formatNumber,
  kFormatter,
} from "../../../../shared/utils/formatNumbers";
import { SelectedFilters } from "../../../types/AppTypes";
import { Filters, FiltersEnum } from "../../../types/ApiTypes";
import { getFilters } from "../../../utils/filterUtils";

type LineChartProps = {
  data: DataProps[];
  pointValues: ValuesProps[];
  areaColor: string;
  maxValue?: number | null;
  selected: SelectedFilters;
  filters?: Filters;
};
type ValuesProps = {
  date: string;
  total: number;
  recalled: number;
  value: number;
};
type DataProps = {
  x: string;
  y: number;
};

export const LineChart = ({
  data,
  pointValues,
  areaColor,
  maxValue,
  selected,
  filters,
}: LineChartProps) => {
  const customTheme = {
    axis: {
      ticks: {
        text: {
          fill: "black",
          fontSize: "12px",
        },
      },
    },
    grid: {
      line: {
        stroke: "#8a9aa9",
      },
    },
  };

  return (
    <ResponsiveLine
      data={[
        {
          id: "lineChart",
          data: (data as DataProps[]) || [],
        },
      ]}
      enableCrosshair={false}
      margin={{ top: 20, right: 20, bottom: 25, left: 45 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        stacked: true,
        reverse: false,
        max: maxValue || undefined,
      }}
      pointSize={3}
      pointColor={"white"}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      lineWidth={5}
      enableGridX={false}
      useMesh={true}
      enableArea={true}
      tooltip={(point) => {
        const index = point.point.index;
        const FILTERS = getFilters(filters);

        const getSelected = (filter: FiltersEnum) =>
          FILTERS[filter]
            ? FILTERS[filter]
                ?.filter((item) => selected[filter].includes(item.id))
                .map((item) => item.label)
            : [];

        //const apptStaFilter = getSelected(FiltersEnum.APPT_STATUS) || [];
        //const apptTypeFilter = getSelected(FiltersEnum.APPT_TYPE) || [];
        const locFilters = getSelected(FiltersEnum.LOCATIONS) || [];
        const provFilters = getSelected(FiltersEnum.PROVIDERS) || [];

        return (
          <ContentContainer padding={15}>
            <div className="popup-chart-container">
              <div className="top-text">
                <p>{`${moment(pointValues[index].date).format(
                  "MMMM"
                )} - Total Existing Patients: ${formatNumber(
                  pointValues[index].total
                )}`}</p>
                <p>Filtered By:</p>
                <ul>
                  <li>
                    <span>
                        {"All Appointments Status"}
                    </span>
                  </li>
                  <li>
                    <span>
                        {"All Appointments Type(s)"}
                    </span>
                  </li>
                  <li>
                    <span>
                      {selected[FiltersEnum.LOCATIONS].length
                        ? locFilters.join(", ")
                        : "All Locations"}
                    </span>
                  </li>
                  <li>
                    <span>
                      {selected[FiltersEnum.PROVIDERS].length
                        ? provFilters.join(", ")
                        : "All Providers"}
                    </span>
                  </li>
                </ul>
              </div>
              <p>{`Recalled Patients: ${formatNumber(
                pointValues[index].recalled
              )}`}</p>
            </div>
          </ContentContainer>
        );
      }}
      areaOpacity={1}
      colors={areaColor}
      gridYValues={6}
      pointLabelYOffset={0}
      enablePointLabel={false}
      axisTop={null}
      axisLeft={{
        tickValues: 5,
        tickSize: 0,
        tickPadding: 12,
        format: (tick) => kFormatter(tick),
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        format: (d: string) => `${moment(d).format("MMM")}`,
      }}
      theme={customTheme}
      layers={[
        "grid",
        "axes",
        "areas",
        "mesh",
        (d) => (
          <>
            {d.points.map(
              (point, index) =>
                index < d.points.length - 1 && (
                  <line
                    key={point.id}
                    x1={point.x}
                    y1={point.y}
                    x2={d.points[index + 1].x}
                    y2={d.points[index + 1].y}
                    style={{
                      stroke: "grey",
                      strokeWidth: 3,
                    }}
                  />
                )
            )}
          </>
        ),
        (d) => (
          <>
            {d.points.map((point) => (
              <circle
                key={point.id}
                cx={point.x}
                cy={point.y}
                r={d.pointSize}
                style={{
                  fill: "white",
                  stroke: "grey",
                  strokeWidth: 2,
                }}
              />
            ))}
          </>
        ),
      ]}
    />
  );
};
