import {
  CancelledApptColumns,
  CancelledRecColumns,
  NoShowColumns,
  OrdersColumns,
  PreApptColumns,
  PurchaseColumns,
  VisitColumns,
  WebApptColumns,
} from "./AppTypes";

// comment out for now 7/26/2023
//export enum FiltersEnum {
//  APPT_STATUS = "apptStatus",
//  LOCATIONS = "locations",
//  APPT_TYPE = "apptTypes",
//  PROVIDERS = "providers",
//}

export enum FiltersEnum {
  LOCATIONS = "locations",
  PROVIDERS = "providers",
}

// comment out for now 7/26/2023
//export interface Filters {
//  [FiltersEnum.APPT_STATUS]: string[];
//  [FiltersEnum.APPT_TYPE]: string[];
//  [FiltersEnum.LOCATIONS]: { [key: string]: string };
//  [FiltersEnum.PROVIDERS]: { [key: string]: string };
//}

export interface Filters {
  [FiltersEnum.LOCATIONS]: { [key: string]: string };
  [FiltersEnum.PROVIDERS]: { [key: string]: string };
}

export type Modality = {
  [key in ModalityEnum]: {
    delta: number;
    values: [
      {
        date: string;
        total: number;
        recalled: number;
        value: number;
      }
    ];
  };
};
export enum PatientsEnum {
  TOTAL = "total",
  EXISTING = "existing",
  NEW = "newPatients",
  RECALLED = "recalled",
}
export type PercentageData = {
  percentage: number;
  value: number;
};
export interface Total {
  total: PercentageData;
  existing: PercentageData;
  newPatients: PercentageData;
  existingRecalled: PercentageData;
  touches: number;
}
export type Recalled = {
  date: string;
  efficiency: number;
  existing: number;
  recall: number;
  touches: number;
}[];
export enum ModalityEnum {
  SMS = "sms",
  EMAIL = "email",
  VOICE = "voice",
  POSTAL = "postal",
}
export interface EssilorLocation {
  locKey: number;
  locationName: string;
}
export interface EssilorProvider {
  acctKey: number;
  providerName: string;
}
export interface ApptResponse<T> {
  totalItemCount: number;
  displayedColumns: T[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pagedItems: any[];
}
export interface WebApptResponse extends ApptResponse<WebApptColumns> {
  pagedItems: WebAppointment[];
}
export interface WebAppointment {
  acctKey: number;
  addKey: number;
  address1: string;
  address2: string;
  apptKey: number;
  apptSchedTime: string;
  apptStatus: number;
  cell: string;
  city: string;
  country: string;
  coverKey: number;
  created: string;
  dob: string;
  email: string;
  endTime: string;
  firstName: string;
  gender: number;
  home: string;
  insName: string;
  isNewOrRescheduled: string;
  lang: string;
  lastName: string;
  locKey: number;
  localKey: string;
  locationText: string;
  notes: string;
  patientID: string;
  providerText: string;
  reason: string;
  sponsored: number;
  ssn: string;
  startTime: string;
  state: string;
  taskKey: number;
  taskName: string;
  work: string;
  writebackResult: string;
  zip: string;
}
export interface WebApptWeek {
  date: string;
  totalCount: number;
  subList: [
    {
      date: string;
      source: string;
      dayOfWeek: number;
      count: number;
    }
  ];
}
export interface PreApptResponse extends ApptResponse<PreApptColumns> {
  pagedItems: PreAppointment[];
}
export interface PreAppointment {
  acctKey: number;
  addKey: number;
  apptID: string;
  apptKey: number;
  commAt: string;
  commAtDatetime: string;
  commKey: number;
  commModality: string;
  commStatus: number;
  commStatusText: string;
  commTo: string;
  commType: number;
  coverKey: number;
  endingMesg: number;
  firstName: string;
  lastName: string;
  locKey: number;
  locationName: string;
  patientID: string;
  preApptOrRem: string;
  profileID: string;
  providerName: string;
  reason: string;
  response: string;
  responseAt: string;
  responseAtDatetime: string;
  startTime: string;
  startTimeDatetime: string;
}
export interface RecallsResponse extends ApptResponse<PreApptColumns> {
  pagedItems: RecallsAppointment[];
}
export interface RecallsAppointment {
  commModality: string;
  commTo: string;
  commAt: string;
  commStatusText: string;
  patientID: string;
  firstName: string;
  lastName: string;
  response: string;
  uploadedOn: string;
  coverKey: number;
  locKey: number;
  locationName: string;
  acctKey: number;
  providerName: string;
  commKey: string;
  addKey: number;
  profileID: string;
  endingMesg: number;
  lastVisitDate: string;
  dateAndTime: string;
  recallReason: string;
}
export interface OrdersResponse extends ApptResponse<OrdersColumns> {
  pagedItems: Orders[];
}
export interface Orders {
  commModality: string;
  commTo: string;
  commAt: string;
  commAtDate: string;
  commAtTime: string;
  commStatusText: string;
  patientID: string;
  firstName: string;
  lastName: string;
  response: string;
  orderNumber: string;
  itemType: string;
  commAtDateOnly: string;
  location: string;
  coverKey: number;
  locKey: number;
  acctKey: number;
  commType: number;
  commKey: number;
  commStatus: number;
  addKey: number;
  responseAt: string;
  profileID: string;
}
export interface CancellationRecResponse
  extends ApptResponse<CancelledRecColumns> {
  pagedItems: CancelledRecapture[];
}
export interface CancelledRecapture {
  commModality: string;
  commTo: string;
  commAt: string;
  commStatusText: string;
  patientID: string;
  firstName: string;
  lastName: string;
  response: number;
  startTime: string;
  reason: string;
  preApptOrRem: string;
  commAtDatetime: string;
  coverKey: number;
  locKey: number;
  locationName: string;
  acctKey: number;
  providerName: string;
  commKey: string;
  endingMesg: number;
  addKey: number;
  responseAt: string;
  profileID: string;
  apptKey: number;
  appointmentId: number;
  communicationTypeId: number;
}
export interface CancellationApptResponse
  extends ApptResponse<CancelledApptColumns> {
  pagedItems: CancelledAppt[];
}
export interface CancelledAppt {
  patientName: string;
  apptStartDate: string;
  locationName: string;
  providerName: string;
  apptReason: string;
  email: string;
  cellPhone: string;
  homePhone: string;
  workPhone: string;
  address: string;
  patientDOB: string;
  cancelledDate: string;
  acctKey: string;
  locKey: string;
}
export interface PurchaseResponse extends ApptResponse<PurchaseColumns> {
  pagedItems: Purchase[];
}
export interface Purchase {
  commModality: string;
  commTo: string;
  commAt: string;
  commStatusText: string;
  patientID: string;
  firstName: string;
  lastName: string;
  response: number;
  startTime: string;
  reason: string;
  preApptOrRem: string;
  commAtDatetime: string;
  coverKey: number;
  locKey: number;
  locationName: string;
  acctKey: number;
  providerName: string;
  commKey: string;
  endingMesg: number;
  addKey: number;
  responseAt: string;
  profileID: string;
  apptKey: number;
  appointmentId: number;
  communicationTypeId: number;
}
export interface VisitsResponse extends ApptResponse<VisitColumns> {
  pagedItems: Visits[];
}
export interface Visits {
  acctKey: number;
  addKey: number;
  commKey: string;
  commModality: string;
  coverKey: number;
  created: string;
  locKey: number;
  localApptID: string;
  localVisitID: string;
  pmsVisitCreated: string;
  patientID: string;
  patientShowedUp: number;
  previousVisitCount: number;
  visitAt: string;
  visitKey: number;
  visitMod: string;
  visitReason: string;
  visitType: string;
}
export interface NoShowResponse extends ApptResponse<NoShowColumns> {
  pagedItems: NoShow[];
}
export interface NoShow {
  visitKey: number;
  coverKey: number;
  locKey: number;
  acctKey: number;
  addKey: number;
  localApptID: string;
  visitType: string;
  visitReason: string;
  visitAt: string;
  patientShowedUp: number;
  pmsVisitCreated: string;
  created: string;
  localVisitID: string;
  visitMod: string;
  patientID: string;
  commModality: string;
  commKey: string;
  previousVisitCount: number;
  firstName: string;
  lastName: string;
}
export interface ApptBody {
  beginDate: string;
  endDate: string;
  pageStart: number;
  pageSize: number;
  searchPhrase: string;
  sortColumn: string;
  sortOrder: ApptSort;
}
export interface WebAppointmentsBody extends ApptBody {
  reportType: WebApptType;
  locKeys: number[];
  acctKeys: number[];
}
export interface PreAppointmentsBody extends ApptBody {
  reportType: PreApptType;
  commMethods: CommMethods[];
  locKeys: number[];
  acctKeys: number[];
}
export interface RecallsBody extends ApptBody {
  commMethods: CommMethods[];
  reportType: RecallApptType;
  locKeys: number[];
  acctKeys: number[];
}
export interface OrdersBody extends ApptBody {
  commMethods: CommMethods[];
  locKeys: number[];
  acctKeys: number[];
  reportType: PreApptType;
}
export interface CancellationBody extends ApptBody {
  reportType: RecallApptType;
  locKeys: number[];
  acctKeys: number[];
}
export interface VisitBody extends ApptBody {
  locKeys: number[];
  acctKeys: number[];
}
export enum WebApptType {
  APPT_CREATED = "ApptCreated",
  APPT = "Appt",
}
export enum PreApptType {
  MSG = "Msg",
  APPT = "Appt",
}
export enum ApptSort {
  NOT_SORTED = "NotSorted",
  ASC = "Ascending",
  DESC = "Descending ",
}
export enum ApptFile {
  PDF = "pdf",
  XLSX = "xlsx",
  CSV = "csv",
}
export enum CommMethods {
  EMAIL = "Email",
  TEXT = "Text",
  PHONE = "Phone",
  POSTAL = "Postal",
}
export enum RecallApptType {
  MESSAGED = "Messaged",
  UPLOADED = "Uploaded",
}
