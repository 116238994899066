import { IsErrorApi } from "../services/IsErrorApi";
import { useEffect, useState } from "react";
import { Observable } from "rxjs";
import { AjaxError } from "rxjs/ajax";

export const useApi = <T>(
  observable: Observable<T | AjaxError>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies?: any[],
  noResetValueOnRefresh?: boolean
) => {
  const [response, setResponse] = useState<T | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    !noResetValueOnRefresh && setResponse(undefined);
    setLoading(true);
    setError(false);
    const subscribe = observable.subscribe((res) => {
      setLoading(false);
      if (IsErrorApi(res)) {
        //console.log('res--------23', res);
        //console.log('response--------24', response);
        //console.log('error--------25', error);
        //console.log('loading--------26', loading);
        setError(true);
      } else {
        //console.log('res----------29', res);
        setResponse(res as T);
      }
    });
    return () => subscribe.unsubscribe();
  }, dependencies || []);

  //console.log('resp-----------36', response, error, loading);
  return [response, error, loading] as [T | undefined, boolean, boolean];
};
