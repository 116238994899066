import React from "react";
import { ContentContainer } from "../../Atoms/ContentContainer/ContentContainer";
import "./ProgressCard.css";

type ProgressCardProps = {
  percentage: number | string;
  color: string;
  description: string;
  prefix?: string;
  isNumber?: boolean;
};
export const ProgressCard = ({
  percentage,
  color,
  isNumber,
  prefix,
  description,
}: ProgressCardProps) => (
  <ContentContainer padding={8} className="progress-card-wrapper">
    <div
      className={`progress-card-container ${
        getColorLUMA(color) < 110 ? "ligth" : "dark"
      }`}
      style={{ background: color }}
    >
      {prefix && <span className="progress-card-text">{prefix}</span>}
      <span className="progress-card-number">{`${percentage}${
        !isNumber ? "%" : ""
      }`}</span>
      <span className="progress-card-text">{description}</span>
    </div>
  </ContentContainer>
);

const getColorLUMA = (color: string) => {
  const c = color.substring(1);
  const rgb = parseInt(c, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};
