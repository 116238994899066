/* eslint-disable prettier/prettier */
import {
  getRecallTotal,
  getRecallModality,
  getRecallRecalled,
  getApptLocations,
  getApptProviders,
  getAppt,
  setApptColumns,
  getWebApptWeek,
} from "./../apis/MainApis";
import { catchError, map, Observable, of } from "rxjs";
import { AjaxError, AjaxResponse } from "rxjs/ajax";
import {
  Filters,
  Modality,
  Recalled,
  Total,
  EssilorLocation,
  EssilorProvider,
  ApptFile,
  WebApptWeek,
} from "../types/ApiTypes";
import { getFilters } from "../apis/MainApis";
import { ApptApi } from "../types/AppTypes";

export class MainService {
  getFilters(coverkey: string): Observable<Filters | AjaxError> {
    return getFilters(coverkey).pipe(
      map((res) => {
        //console.log('res----------30', res);
        return { ...res };
      }),
      catchError((err) => {
        //console.log('err---------34', err);
        return of(err as AjaxError);
      })
    );
  }
  getRecallTotal(
    //status: string[],
    //type: string[],
    locations: string[],
    providers: string[],
    to: string,
    from: string,
    coverkey: string
  ): Observable<AjaxResponse<Total> | AjaxError> {
    return getRecallTotal(
      //status,
      //type,
      locations,
      providers,
      to,
      from,
      coverkey
    ).pipe(
      map((res) => {
        //console.log('resgetRecallTotal---------58', res);
        return { ...res };
      }),
      catchError((err) => {
        //console.log('errgetRecallTotal---------62', err);
        return of(err as AjaxError);
      })
    );
  }
  getRecallRecalled(
    //status: string[],
    //type: string[],
    locations: string[],
    providers: string[],
    to: string,
    from: string,
    coverkey: string
  ): Observable<AjaxResponse<Recalled> | AjaxError> {
    return getRecallRecalled(
      //status,
      //type,
      locations,
      providers,
      to,
      from,
      coverkey
    ).pipe(
      map((res) => {
        //console.log('resgetRecallRecalled--------86', res);
        return {...res};
      }),
      catchError((err) => {
        //console.log('errgetRecallRecalled--------90', err);
        return of(err as AjaxError);
      })
    );
  }
  getRecallModality(
    //status: string[],
    //type: string[],
    locations: string[],
    providers: string[],
    to: string,
    from: string,
    coverkey: string
  ): Observable<AjaxResponse<Modality> | AjaxError> {
    return getRecallModality(
      //status,
      //type,
      locations,
      providers,
      to,
      from,
      coverkey
    ).pipe(
      map((res) => {
        //console.log('resgetRecallModality--------114', res);
        return { ...res };
      }),
      catchError((err) => {
        //console.log('errgetRecallModality---------118', err);
        return of(err as AjaxError);
      })
    );
  }

  getApptLocations(
    coverKey: number,
    JWT: string
  ): Observable<EssilorLocation[] | AjaxError> {
    return getApptLocations(coverKey, JWT).pipe(
      map((res) => {
        return [...res];
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
  getApptProviders(
    coverKey: number,
    JWT: string
  ): Observable<EssilorProvider[] | AjaxError> {
    return getApptProviders(coverKey, JWT).pipe(
      map((res) => {
        return [...res];
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
  getWebApptWeek(
    coverKey: number,
    JWT: string,
    date: string
  ): Observable<WebApptWeek[] | AjaxError> {
    return getWebApptWeek(coverKey, JWT, date).pipe(
      map((res) => {
        return [...res];
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
  getAppt<T1, T2>(
    coverKey: number,
    JWT: string,
    body: T1,
    apiName: ApptApi,
    fileType?: ApptFile
  ): Observable<T2 | AjaxError> {
    return getAppt<T1, T2>(coverKey, JWT, body, apiName, fileType).pipe(
      map((res) => {
        return !fileType ? ({ ...res.response } as T2) : res.response;
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
  setApptColumns<T>(
    coverKey: number,
    JWT: string,
    columns: T[],
    apiName: ApptApi
  ): Observable<AjaxResponse<void> | AjaxError> {
    return setApptColumns<T>(coverKey, JWT, columns, apiName).pipe(
      map((res) => {
        return { ...res };
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
}

export default new MainService();
