import React from "react";
import "./Legend.css";

type LegendProps = {
  data: dataProps[];
};

type dataProps = {
  color: string;
  label: string;
};

export const Legend = ({ data }: LegendProps) => {
  return (
    <div className="legend-container">
      {data.map((item, idx) => (
        <div key={`legend-${idx}`} className="legend-item">
          <div
            className="legend-item-color"
            style={{ backgroundColor: item.color }}
          />
          <div className="legend-item-text">{item.label}</div>
        </div>
      ))}
    </div>
  );
};
