import { CancelledApptColumns, CancelledRecColumns } from "./../types/AppTypes";
import moment from "moment";
import { ApptSort, ModalityEnum, PatientsEnum } from "../types/ApiTypes";
import {
  NoShowColumns,
  OrdersColumns,
  PreApptColumns,
  PurchaseColumns,
  VisitColumns,
  WebApptColumns,
} from "../types/AppTypes";

export const DATE_FORMAT = "MM/DD/YYYY";
export const APPT_COLORS = {
  [PatientsEnum.TOTAL]: "#79aada",
  [PatientsEnum.EXISTING]: "#f6c481",
  [PatientsEnum.NEW]: "#335888",
};
export const PERCENTAGE_COLORS = {
  [PatientsEnum.EXISTING]: "#3875bf",
  [PatientsEnum.RECALLED]: "#f6c481",
};
export const REPORT_COLORS = {
  [PatientsEnum.TOTAL]: "#3875bf",
  [PatientsEnum.RECALLED]: "#f6c481",
  touches: "#335888",
};
export const DEFAULT_DATE = {
  from: "",
  to: "",
};
export const MODALITY_COLOR = {
  [ModalityEnum.POSTAL]: "#3875bf",
  [ModalityEnum.SMS]: "#79aada",
  [ModalityEnum.EMAIL]: "#335888",
  [ModalityEnum.VOICE]: "#79aada",
};
export const WEB_APPT_COLUMNS_DEFINE = [
  { id: WebApptColumns.PATIENT_INFO, label: "Patient Info" },
  { id: WebApptColumns.CONTACTS, label: "Contacts" },
  { id: WebApptColumns.APPT_ID, label: "Appt. ID" },
  { id: WebApptColumns.APPT_DATE, label: "Appt. Date/Time" },
  { id: WebApptColumns.LOC_PROV_NAME, label: "Provider Name" },
  { id: WebApptColumns.APPT_REASON, label: "Appt. Reason" },
  { id: WebApptColumns.INSURANCE_CARRIER, label: "Insurance Carrier" },
  { id: WebApptColumns.NOTE, label: "Note" },
];
export const WEB_APPT_WEEK_COLUMNS_DEFINE = [
  { id: "DAY", label: "Day" },
  { id: "DATE_TIME", label: "Date/Time" },
  { id: "COUNT", label: "Count" },
];
export const PRE_APPT_COLUMNS_DEFINE = [
  { id: PreApptColumns.DATE_TIME, label: "Date/Time" },
  { id: PreApptColumns.PATIENT_ID, label: "Patient ID" },
  { id: PreApptColumns.PATIENT_NAME, label: "Patient Name" },
  { id: PreApptColumns.LOCAL_PROVIDER_NAME, label: "Local & Provider Name" },
  { id: PreApptColumns.COMM_METHOD, label: "Comm. Method" },
  { id: PreApptColumns.COMM_INFO, label: "Comm. Info Used" },
  { id: PreApptColumns.STATUS, label: "Status" },
  { id: PreApptColumns.RESPONSE, label: "Response" },
  { id: PreApptColumns.RECALL_REASON, label: "Recall Reason" },
];
export const ORDERS_COLUMNS_DEFINE = [
  { id: OrdersColumns.DATE_TIME, label: "Date/Time" },
  { id: OrdersColumns.PATIENT_ID, label: "Patient ID" },
  { id: OrdersColumns.PATIENT_NAME, label: "Patient Name" },
  { id: OrdersColumns.LOCATION_NAME, label: "Location" },
  { id: OrdersColumns.COMM_METHOD, label: "Comm. Method" },
  { id: OrdersColumns.COMM_INFO, label: "Comm. Info Used" },
  { id: OrdersColumns.ORDER_NUMBER, label: "Order Number" },
  { id: OrdersColumns.ITEM_TYPE, label: "Item Type" },
  { id: OrdersColumns.STATUS, label: "Status" },
  { id: OrdersColumns.RESPONSE, label: "Response" },
];
export const CANCELLATIONS_COLUMNS_DEFINE = [
  { id: CancelledApptColumns.DATE_TIME, label: "Appt. Date/Time Cancelled On" },
  { id: CancelledApptColumns.CONTACT, label: "Contact" },
  {
    id: CancelledApptColumns.LOCAL_PROVIDER_NAME,
    label: "Local & Provider Name",
  },
  { id: CancelledApptColumns.APPT_REASON, label: "Appt. Reason" },
  { id: CancelledApptColumns.DOB, label: "DOB" },
];
export const CANCELLATIONS_REC_COLUMNS_DEFINE = [
  { id: CancelledRecColumns.DATE_TIME, label: "Date/Time" },
  { id: CancelledRecColumns.PATIENT_ID, label: "Patient ID" },
  { id: CancelledRecColumns.PATIENT_NAME, label: "Patient Name" },
  { id: CancelledRecColumns.LOCATION, label: "Location" },
  { id: CancelledRecColumns.COMM_METHOD, label: "Comm. Method" },
  { id: CancelledRecColumns.COMM_INFO, label: "Comm. Info Used" },
  { id: CancelledRecColumns.STATUS, label: "Status" },
  { id: CancelledRecColumns.RECALL_REASON, label: "Cancelled Reason" },
];
export const PURCHASE_COLUMNS_DEFINE = [
  { id: PurchaseColumns.TIME_SEND, label: "Time Sent" },
  { id: PurchaseColumns.DATE_TIME, label: "Appt. Date/Time" },
  { id: PurchaseColumns.PATIENT_ID, label: "Patient ID" },
  { id: PurchaseColumns.PATIENT_NAME, label: "Patient Name" },
  { id: PurchaseColumns.STATUS, label: "Status" },
  { id: PurchaseColumns.LOCAL_PROVIDER_NAME, label: "Local & Provider Name" },
];
export const VISIT_COLUMNS_DEFINE = [
  { id: VisitColumns.DATE_TIME, label: "Visit Date" },
  { id: VisitColumns.APPT_REASON, label: "Reason" },
  { id: VisitColumns.STATUS, label: "Status" },
  { id: VisitColumns.PATIENT_ID, label: "4PC PatientID" },
  { id: VisitColumns.VISIT_ID, label: "4PC VisitID" },
];
export const NO_SHOW_COLUMNS_DEFINE = [
  { id: NoShowColumns.DATE_TIME, label: "Date/Time" },
  { id: NoShowColumns.PATIENT_ID, label: "Patient ID" },
  { id: NoShowColumns.PATIENT_NAME, label: "Patient Name" },
  { id: NoShowColumns.APPT_TYPE, label: "Appt. Type" },
  { id: NoShowColumns.APPT_REASON, label: "Appt. Reason" },
];

export const APPT_PAGE_LIMIT = 10;
export const DEFAULT_APPT_BODY = {
  pageSize: APPT_PAGE_LIMIT,
  pageStart: 1,
  beginDate: moment().startOf("day").format(),
  endDate: moment().endOf("day").format(),
  searchPhrase: "",
  sortColumn: "",
  sortOrder: ApptSort.NOT_SORTED,
};
