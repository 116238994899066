import { capitalize } from "../../shared/utils/stringUtils";
import {
  PRE_APPT_TYPE_LABEL,
  RECALL_APPT_TYPE_LABEL,
  WEB_APPT_TYPE_LABEL,
} from "../constants/Dictionary";
import {
  CommMethods,
  PreApptType,
  RecallApptType,
  WebApptType,
} from "../types/ApiTypes";

export const getWebApptTypes = () =>
  Object.values(WebApptType).map((item) => ({
    id: item,
    label: WEB_APPT_TYPE_LABEL[item],
  }));

export const getPreApptTypes = () =>
  Object.values(PreApptType).map((item) => ({
    id: item,
    label: PRE_APPT_TYPE_LABEL[item],
  }));

export const getRecallsApptTypes = () =>
  Object.values(RecallApptType).map((item) => ({
    id: item,
    label: RECALL_APPT_TYPE_LABEL[item],
  }));

export const getApptCommMethods = () =>
  Object.entries(CommMethods).map(([, value]) => ({
    id: value,
    label: capitalize(value),
  }));
