import React from "react";
import { LoadingError } from "../../components";
import { Table } from "../../components/Molecules/Table/Table";
import {
  DATE_FORMAT,
  PURCHASE_COLUMNS_DEFINE,
} from "../../constants/AppConstants";
import { Purchase } from "../../types/ApiTypes";
import moment from "moment";
import { capitalize } from "../../../shared/utils/stringUtils";

export const PurchaseApptTable = ({
  res,
  loading,
  columns,
  error,
}: {
  res?: Purchase[];
  loading?: boolean;
  columns: string[];
  error?: boolean;
}) => {
  const userColumns = PURCHASE_COLUMNS_DEFINE.map((item, idx) => ({
    ...item,
    idx,
  })).filter((item) => columns.includes(item.id));
  const columnsIndexes = userColumns.map((item) => item.idx);
  const tableData =
    res &&
    res
      .map((item) => [
        moment(item.commAtDatetime).format(`${DATE_FORMAT} hh:mm A`),
        moment(item.startTime).format(`${DATE_FORMAT} hh:mm A`),
        capitalize(item.patientID),
        `${capitalize(item.firstName)} ${capitalize(item.lastName)}`,
        capitalize(item.commStatusText),
        `${capitalize(item.locationName)} / ${capitalize(item.providerName)}`,
      ])
      .map((item) => item.filter((_, idx) => columnsIndexes.includes(idx)));

  return !error ? (
    <>
      <Table
        colums={userColumns.map((item) => item.label)}
        datas={tableData || []}
        loading={loading}
      />
    </>
  ) : (
    <LoadingError />
  );
};
