import React from "react";
import { Table } from "../../components/Molecules/Table/Table";
import {
  DATE_FORMAT,
  NO_SHOW_COLUMNS_DEFINE,
  VISIT_COLUMNS_DEFINE,
} from "../../constants/AppConstants";
import { NoShow, Visits } from "../../types/ApiTypes";
import { LoadingError } from "../../components";
import moment from "moment";
import { capitalize } from "../../../shared/utils/stringUtils";

export const VISITS_TABS = [
  {
    id: 0,
    label: "Visit Data Report",
  },
  {
    id: 1,
    label: "No Show Report",
  },
];

export const NoShowTable = ({
  res,
  loading,
  columns,
  error,
}: {
  res?: NoShow[];
  loading?: boolean;
  columns: string[];
  error?: boolean;
}) => {
  const userColumns = NO_SHOW_COLUMNS_DEFINE.map((item, idx) => ({
    ...item,
    idx,
  })).filter((item) => columns.includes(item.id));
  const columnsIndexes = userColumns.map((item) => item.idx);
  const tableData =
    res &&
    res
      .map((item) => [
        moment(item.created).format(`${DATE_FORMAT} hh:mm A`),
        capitalize(item.patientID),
        `${capitalize(item.firstName)} ${capitalize(item.lastName)}`,
        capitalize(item.visitType),
        capitalize(item.visitReason),
      ])
      .map((item) => item.filter((_, idx) => columnsIndexes.includes(idx)));

  return !error ? (
    <>
      <Table
        colums={userColumns.map((item) => item.label)}
        datas={tableData || []}
        loading={loading}
      />
    </>
  ) : (
    <LoadingError />
  );
};

export const VisitTable = ({
  res,
  loading,
  columns,
  error,
}: {
  res?: Visits[];
  loading?: boolean;
  columns: string[];
  error?: boolean;
}) => {
  const userColumns = VISIT_COLUMNS_DEFINE.map((item, idx) => ({
    ...item,
    idx,
  })).filter((item) => columns.includes(item.id));
  const columnsIndexes = userColumns.map((item) => item.idx);
  const tableData =
    res &&
    res
      .map((item) => [
        moment(item.pmsVisitCreated).format(`${DATE_FORMAT} hh:mm A`),
        capitalize(item.visitReason),
        capitalize(String(item.patientShowedUp)),
        capitalize(item.patientID),
        capitalize(item.localVisitID),
      ])
      .map((item) => item.filter((_, idx) => columnsIndexes.includes(idx)));

  return !error ? (
    <>
      <Table
        colums={userColumns.map((item) => item.label)}
        datas={tableData || []}
        loading={loading}
      />
    </>
  ) : (
    <LoadingError />
  );
};
