import React from "react";
import "./MultiSelect.css";
import { IoIosArrowDown } from "react-icons/io";
import { useInfiniteScroll } from "../../../../shared/hooks/useInfiniteScroll";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import { SELECT_ALL_LABEL } from "../../../constants/Dictionary";

interface MultiSelectProps {
  options: {
    id: string;
    label: string;
  }[];
  label: string;
  selected: string[];
  setSelected: (sel: string[]) => void;
  showSelectAll?: boolean;
}
export const MultiSelect = ({
  options,
  label,
  selected,
  setSelected,
  showSelectAll,
}: MultiSelectProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const MAX_RENDER = 100;
  const [offset, lastOption] = useInfiniteScroll(options.length, MAX_RENDER);
  const [search, setSearch] = React.useState<string>("");
  const [selectAll, setSelectAll] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      });
      return () => {
        document.removeEventListener("mousedown", () => null);
      };
    }
  }, [open]);

  React.useEffect(() => {
    options.length === selected.length
      ? setSelectAll(true)
      : setSelectAll(false);
  }, [selected]);

  const filtersId = options.map((item) => item.id);

  const handelSelectAll = () => {
    !selectAll ? setSelected([...filtersId]) : setSelected([]);
  };

  const showSelectAllField =
    showSelectAll && search.length === 0 && options.length > 0;

  return (
    <div ref={ref} className={`multiselect-container ${open && "open"}`}>
      <div className="multiselect-button" onClick={() => setOpen(!open)}>
        <div className="label-text truncate">
          <label>{label}</label>
        </div>
        <IoIosArrowDown className="icon-multiselect animate" />
      </div>
      <div className="multiselect-panel">
        <div className="multiselect-filter-container">
          <MdOutlineClose
            className="reset-filter-multiselect"
            onClick={() => setSearch("")}
          />
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
          />
          <AiOutlineSearch />
        </div>
        <div className="scroll">
          {showSelectAllField && (
            <div className="multiselect-option">
              <input
                type="checkbox"
                onChange={handelSelectAll}
                checked={selectAll}
              />
              <label>{SELECT_ALL_LABEL}</label>
            </div>
          )}
          {options
            .filter((item) =>
              item.label.toLowerCase().includes(search.toLowerCase())
            )
            .slice(0, MAX_RENDER * offset)
            .map((option, index, array) => (
              <div
                className="multiselect-option"
                key={`fil-opt-${option.id}-${index}`}
                ref={index === array.length - 1 ? lastOption : undefined}
              >
                <input
                  type="checkbox"
                  id={option.id}
                  onChange={() => {
                    if (selected.includes(option.id)) {
                      setSelected(selected.filter((id) => id !== option.id));
                    } else {
                      setSelected([...selected, option.id]);
                    }
                  }}
                  checked={selected.includes(option.id)}
                />
                <label>{option.label}</label>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export const MultiSelectWithLabel = (
  props: MultiSelectProps & { valueLabel?: string } & {
    showSelectAll?: boolean;
  }
) => (
  <div className="multiselect-label-container">
    <p className="multiselect-label-text">{props.label}</p>
    <MultiSelect {...props} label={props.valueLabel || "All Selected"} />
  </div>
);
