import moment from "moment";
import React from "react";
import "./EfficiencyCard.css";
import { formatNumber } from "../../../../shared/utils/formatNumbers";
import { BarChart } from "../../Charts/BarChart/BarChart";
import { ContentContainer } from "../../Atoms/ContentContainer/ContentContainer";

type EfficiencyCardProps = {
  id: string;
  data: dataProps;
};

type dataProps = {
  date: string;
  efficiency: number;
  existing: number;
  recall: number;
  touches: number;
};

export const EfficiencyCard = ({ id, data }: EfficiencyCardProps) => {
  const dataBarChart = [
    { bar: "Existing", value: data.existing },
    { bar: "Recall", value: data.recall },
  ];

  const maxValue = Math.max(data.existing, data.recall);

  return (
    <ContentContainer padding={25}>
      <div className="efficiency-card-main">
        <div className="efficiency-card-left-container">
          <div className="top-text-container">
            <span className="date">
              {moment(data.date).format("MMMM YYYY")}
            </span>
            <span className="efficiency">{`${formatNumber(
              data.efficiency,
              2
            )}%`}</span>
            <span className="title">Recall Efficiency</span>
          </div>
          <div className="bottom-text-container">
            <p>{`Average number of modality touches per patient is ${formatNumber(
              data.touches,
              2
            )} for this month.`}</p>
          </div>
        </div>
        <div className="efficiency-card-right-container">
          <div className="chartBar-container">
            <BarChart
              id={id}
              data={dataBarChart}
              maxValue={maxValue + maxValue / 3}
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};
