import React from "react";

export const useInfiniteScroll = (
  totalElements: number,
  maxPerPage: number
) => {
  const [offset, setOffset] = React.useState<number>(1);

  const hasMore = maxPerPage * (offset + 1) <= totalElements;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = React.useRef<any>();
  const lastOption = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return [offset, lastOption] as [number, (node: any) => void];
};
