import { PreApptType, RecallApptType, WebApptType } from "./../types/ApiTypes";
import { FiltersEnum, ModalityEnum, PatientsEnum } from "../types/ApiTypes";

export const APPT_LABELS: {
  [key in "existing" | "total" | "newPatients"]: string;
} = {
  [PatientsEnum.TOTAL]: "Scheduled Appointments",
  [PatientsEnum.EXISTING]: "Existing Patients",
  [PatientsEnum.NEW]: "New Patients",
};

// comment out for now 7/26/2023
//export const FILTERS_LABEL: { [key in FiltersEnum]: string } = {
//  [FiltersEnum.APPT_STATUS]: "Appointment Status",
//  [FiltersEnum.APPT_TYPE]: "Appointment Types",
//  [FiltersEnum.LOCATIONS]: "Locations",
//  [FiltersEnum.PROVIDERS]: "Providers",
//};

export const FILTERS_LABEL: { [key in FiltersEnum]: string } = {
  [FiltersEnum.LOCATIONS]: "Locations",
  [FiltersEnum.PROVIDERS]: "Providers",
};

export const PERCENTAGE_LABELS: { [key in "existing" | "recalled"]: string } = {
  [PatientsEnum.EXISTING]: "Scheduled Appointments",
  [PatientsEnum.RECALLED]: "Recaptured via Recall",
};
export const MODALITY_LABEL: { [key in ModalityEnum]: string } = {
  [ModalityEnum.POSTAL]: "Mail",
  [ModalityEnum.SMS]: "SMS Text",
  [ModalityEnum.EMAIL]: "Email",
  [ModalityEnum.VOICE]: "Voice",
};
export const WEB_APPT_TYPE_LABEL: { [key in WebApptType]: string } = {
  [WebApptType.APPT]: "Look up by appointment time/date",
  [WebApptType.APPT_CREATED]: "Look up by when appointment was created",
};
export const PRE_APPT_TYPE_LABEL: { [key in PreApptType]: string } = {
  [PreApptType.APPT]: "Look up by appointment time/date",
  [PreApptType.MSG]: "Look up by when appointment was communicated",
};
export const RECALL_APPT_TYPE_LABEL: { [key in RecallApptType]: string } = {
  [RecallApptType.MESSAGED]: "Look up by when appointment was communicated",
  [RecallApptType.UPLOADED]: "Look up by when appointment was uploaded",
};
export const SELECT_ALL_LABEL = "Select all";
