import React from "react";
import ReactDOM from "react-dom";
import { Button } from "../../../../shared/stories/Button.stories";
import "./Alert.css";

interface AlertModalProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
}
const AlertModal = ({ children, footer }: AlertModalProps) => (
  <div className="alert-modal-overlay">
    <div className="alert-modal-container">
      {children}
      {footer || (
        <div className="alert-modal-footer">
          <Button label="Ok" onClick={() => new Alert().closeModal()} />
        </div>
      )}
    </div>
  </div>
);

class Alert {
  public showAlertInfo = (text: string) => {
    this.rebuildRoot();
    ReactDOM.render(
      <AlertModal>
        <span className="alert-modal-text">{text}</span>
      </AlertModal>,
      document.getElementById("root-modal")
    );
  };
  public closeModal = () => {
    document.getElementById("root-modal")?.remove();
  };
  private rebuildRoot = () => {
    const root = document.createElement("div");
    root.id = "root-modal";
    document.body.appendChild(root);
  };
}

export default new Alert();
