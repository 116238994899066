import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import {
  formatNumber,
  kFormatter,
} from "../../../../shared/utils/formatNumbers";

type BarChartProps = {
  data: dataProps[];
  id: string;
  maxValue?: number | null;
};

type dataProps = {
  bar: string;
  value: number;
};

export const BarChart = ({ id, data, maxValue }: BarChartProps) => {
  const colors: Record<string, string> = {
    Existing: "#3875bf",
    Recall: "#f6c481",
  };
  const getColor = (bar: { indexValue: string | number }) =>
    colors[bar.indexValue];

  return (
    <ResponsiveBar
      data={data}
      keys={["value"]}
      indexBy="bar"
      margin={{ top: 10, right: 35, bottom: 20, left: 0 }}
      maxValue={maxValue || undefined}
      padding={0.3}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={getColor}
      axisRight={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: 0,
        tickValues: 4,
        format: (tick) => kFormatter(tick),
      }}
      gridYValues={4}
      axisTop={null}
      axisBottom={null}
      axisLeft={null}
      enableLabel={false}
      theme={{
        grid: {
          line: {
            stroke: "#7f7f7f",
            strokeWidth: 0.3,
          },
        },
        axis: {
          ticks: {
            text: {
              fill: "#7f7f7f",
            },
          },
        },
      }}
      isInteractive={false}
      animate={false}
      layers={[
        "grid",
        "axes",
        (d) => (
          <>
            <defs>
              {d.bars.map((bar, idx) => (
                <clipPath key={`def-${bar.key}`} id={`bar-round-${idx}-${id}`}>
                  <rect
                    x={bar.x + 5}
                    y={bar.y}
                    width={bar.width - 10}
                    height={bar.height + 10}
                    rx="10"
                    ry="10"
                  />
                </clipPath>
              ))}
            </defs>
            {d.bars.map((bar, idx) => (
              <rect
                key={bar.key}
                x={bar.x + 5}
                y={bar.y}
                height={bar.height}
                width={bar.width - 10}
                fill={bar.color}
                clipPath={`url(#bar-round-${idx}-${id})`}
              />
            ))}
          </>
        ),
        (d) => (
          <>
            {d.bars.map((bar) => (
              <text
                key={`label-${bar.key}`}
                textAnchor="middle"
                x={bar.x + 5 + (bar.width - 10) / 2}
                y={d.height - 15}
                dominantBaseline="middle"
                fontSize={10.5}
                fontWeight="bold"
              >
                {formatNumber(bar.data.value || 0)}
              </text>
            ))}
          </>
        ),
      ]}
    />
  );
};
