import React from "react";
import { FaCalendarAlt, FaUserAlt } from "react-icons/fa";
import { PERCENTAGE_COLORS } from "../../../constants/AppConstants";
import { PERCENTAGE_LABELS } from "../../../constants/Dictionary";
import { PatientsEnum, Total } from "../../../types/ApiTypes";
import { formatNumber } from "../../../../shared/utils/formatNumbers";
import { ContentContainer } from "../../Atoms/ContentContainer/ContentContainer";
import { Percentage } from "../../Molecules/Percentage/Percentage";
import "./PercentageReport.css";

type PercentageReportProps = {
  data: Total;
};
export const PercentageReport = ({ data }: PercentageReportProps) => (
  <ContentContainer
    title="Results"
    className="percentage-report-container"
    padding={25}
  >
    <Percentage
      percentage={data.existing.percentage}
      description={PERCENTAGE_LABELS[PatientsEnum.EXISTING]}
      value={formatNumber(data.existing.value)}
      color={PERCENTAGE_COLORS[PatientsEnum.EXISTING]}
      icon={<FaCalendarAlt />}
      progress
    />
    <Percentage
      percentage={data.existingRecalled.percentage}
      description={PERCENTAGE_LABELS[PatientsEnum.RECALLED]}
      value={formatNumber(data.existingRecalled.value)}
      color={PERCENTAGE_COLORS[PatientsEnum.RECALLED]}
      icon={<FaUserAlt />}
      progress
    />
    <p className="percentage-report-description">{`
      Of the ${formatNumber(data.existing.value)} existing patients for
      this practice location(s), ${formatNumber(
        data.existingRecalled.value
      )} were
      recaptured via recall, with the
      average number of touches at
      approximately ${formatNumber(data.touches, 2)}, using a variety of
      unique touchpoint modalities.
    `}</p>
  </ContentContainer>
);
