/* eslint-disable prettier/prettier */
import { ESSILORDITAC_COVERKEY_HEADER_NAME } from "./../../shared/constants/ApiConstants";
import { Observable } from "rxjs";
import {
  API_ENDPOINT,
  API_ENDPOINT_ESSILORDITAC,
  BASE_HEADERS,
  ESSILORDITAC_TOKEN_HEADER_NAME,
} from "../../shared/constants/ApiConstants";
import { ajax, AjaxResponse } from "rxjs/ajax";
import {
  Filters,
  Modality,
  Recalled,
  Total,
  ApptFile,
  EssilorLocation,
  EssilorProvider,
  WebApptWeek,
} from "../types/ApiTypes";
import { ApptApi } from "../types/AppTypes";

const getDateFromTo = (from?: string, to?: string) =>
  `${from && `?dateFrom=${from}`}${
    to && from ? `&dateTo=${to}` : to && `?dateTo=${to}`
  }`;

export const getFilters = (coverkey: string): Observable<Filters> => {
  return ajax.getJSON(`${API_ENDPOINT}filters/${coverkey}`, {
    ...BASE_HEADERS,
  });
};
export const getRecallTotal = (
  //status: string[],
  //type: string[],
  locations: string[],
  providers: string[],
  to: string,
  from: string,
  coverkey: string
): Observable<AjaxResponse<Total>> => {
  const body = {
    coverKey: coverkey,
    //appStatus: status.length > 0 ? status : ["ALL"],
    appStatus: ["ALL"],
    location: locations.length > 0 ? locations : ["ALL"],
    //appType: type.length > 0 ? type : ["ALL"],
    appType: ["ALL"],
    provider: providers.length > 0 ? providers : ["ALL"],
    dateFrom: from.length > 0 ? from : null,
    dateTo: to.length > 0 ? to : null
  };
  return ajax.post(
    `${API_ENDPOINT}recall/total`, body,
    {
      ...BASE_HEADERS
    }
  );
};
export const getRecallRecalled = (
  //status: string[],
  //type: string[],
  locations: string[],
  providers: string[],
  to: string,
  from: string,
  coverkey: string
): Observable<AjaxResponse<Recalled>> => {
  const body = {
    coverKey: coverkey,
    //appStatus: status.length > 0 ? status : ["ALL"],
    appStatus: ["ALL"],
    location: locations.length > 0 ? locations : ["ALL"],
    //appType: type.length > 0 ? type : ["ALL"],
    appType: ["ALL"],
    provider: providers.length > 0 ? providers : ["ALL"],
    dateFrom: from.length > 0 ? from : null,
    dateTo: to.length > 0 ? to : null
  };
  return ajax.post(
    `${API_ENDPOINT}recall/recalled`, body,
    {
      ...BASE_HEADERS,
    }
  );
};
export const getRecallModality = (
  //status: string[],
  //type: string[],
  locations: string[],
  providers: string[],
  to: string,
  from: string,
  coverkey: string
): Observable<AjaxResponse<Modality>> => {
  const body = {
    coverKey: coverkey,
    //appStatus: status.length > 0 ? status : ["ALL"],
    appStatus: ["ALL"],
    location: locations.length > 0 ? locations : ["ALL"],
    //appType: type.length > 0 ? type : ["ALL"],
    appType: ["ALL"],
    provider: providers.length > 0 ? providers : ["ALL"],
    dateFrom: from.length > 0 ? from : null,
    dateTo: to.length > 0 ? to : null
  };
  return ajax.post(
    `${API_ENDPOINT}recall/modality`, body,
    {
      ...BASE_HEADERS,
    }
  );
};

export const getApptLocations = (
  coverKey: number,
  JWT: string
): Observable<EssilorLocation[]> => {
  return ajax.getJSON(`${API_ENDPOINT_ESSILORDITAC}locations/v1/list`, {
    ...BASE_HEADERS,
    [ESSILORDITAC_TOKEN_HEADER_NAME]: `Bearer ${JWT}`,
    [ESSILORDITAC_COVERKEY_HEADER_NAME]: String(coverKey),
  });
};
export const getApptProviders = (
  coverKey: number,
  JWT: string
): Observable<EssilorProvider[]> => {
  return ajax.getJSON(`${API_ENDPOINT_ESSILORDITAC}providers/v1/list`, {
    ...BASE_HEADERS,
    [ESSILORDITAC_TOKEN_HEADER_NAME]: `Bearer ${JWT}`,
    [ESSILORDITAC_COVERKEY_HEADER_NAME]: String(coverKey),
  });
};
export const getWebApptWeek = (
  coverKey: number,
  JWT: string,
  date: string
): Observable<WebApptWeek[]> => {
  return ajax.getJSON(
    `${API_ENDPOINT_ESSILORDITAC}reports/v1/${ApptApi.WEB_APPOINTMENTS}/dailysummary/week?date=${date}`,
    {
      ...BASE_HEADERS,
      [ESSILORDITAC_TOKEN_HEADER_NAME]: `Bearer ${JWT}`,
      [ESSILORDITAC_COVERKEY_HEADER_NAME]: String(coverKey),
    }
  );
};
export const getAppt = <T1, T2>(
  coverKey: number,
  JWT: string,
  body: T1,
  apiName: ApptApi,
  fileType?: ApptFile
): Observable<AjaxResponse<T2>> => {
  return ajax({
    url: `${API_ENDPOINT_ESSILORDITAC}reports/v1/${apiName}${
      fileType ? `/${fileType}` : ``
    }`,
    method: "POST",
    body: body,
    headers: {
      ...BASE_HEADERS,
      [ESSILORDITAC_TOKEN_HEADER_NAME]: `Bearer ${JWT}`,
      [ESSILORDITAC_COVERKEY_HEADER_NAME]: String(coverKey),
    },
    ...(fileType ? { responseType: "blob" } : {}),
  });
};
export const setApptColumns = <T>(
  coverKey: number,
  JWT: string,
  columns: T[],
  apiName: ApptApi
): Observable<AjaxResponse<void>> => {
  return ajax({
    url: `${API_ENDPOINT_ESSILORDITAC}reports/v1/${apiName}/prefs`,
    method: "PUT",
    body: {
      displayedColumns: columns,
    },
    headers: {
      ...BASE_HEADERS,
      [ESSILORDITAC_TOKEN_HEADER_NAME]: `Bearer ${JWT}`,
      [ESSILORDITAC_COVERKEY_HEADER_NAME]: String(coverKey),
    },
  });
};
