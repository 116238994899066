import moment from "moment";
import React, { useState } from "react";
import {
  MdArticle,
  MdCake,
  MdCalendarToday,
  MdEmail,
  MdHome,
  MdHourglassFull,
  MdOutlineSmartphone,
  MdPhoneInTalk,
} from "react-icons/md";
import { capitalize } from "../../../shared/utils/stringUtils";
import {
  Button,
  LoadingError,
  SingleDatePicker,
  useApi,
} from "../../components";
import { Table } from "../../components/Molecules/Table/Table";
import {
  DATE_FORMAT,
  WEB_APPT_COLUMNS_DEFINE,
  WEB_APPT_WEEK_COLUMNS_DEFINE,
} from "../../constants/AppConstants";
import { WebAppointment, WebApptWeek } from "../../types/ApiTypes";
import "../WebAppointments.css";
import MainService from "../../services/MainService";
import { Pie } from "@nivo/pie";
import { formatNumber } from "../../../shared/utils/formatNumbers";

export const WEB_APPT_TABS = [
  {
    id: 0,
    label: "Web Appointments",
  },
  {
    id: 1,
    label: "Web Scheduled Appt Count",
  },
];

export const WebApptTable = ({
  res,
  loading,
  columns,
  error,
}: {
  res?: WebAppointment[];
  loading?: boolean;
  columns: string[];
  error?: boolean;
}) => {
  const userColumns = WEB_APPT_COLUMNS_DEFINE.map((item, idx) => ({
    ...item,
    idx,
  })).filter((item) => columns.includes(item.id));
  const columnsIndexes = userColumns.map((item) => item.idx);
  const tableData =
    res &&
    res
      .map((item, idx) => [
        <div key={`c1-${idx}`} className="table-cell">
          <span>{capitalize(`${item.firstName} ${item.lastName}`)}</span>
          <span>{`${capitalize(item.city)} ${capitalize(item.country)}`}</span>
          <span className="icon-text">
            <MdCake />
            {`${moment(item.dob).format(DATE_FORMAT)}`}
          </span>
        </div>,
        <div key={`c2-${idx}`} className="table-cell">
          <span className="icon-text">
            <MdOutlineSmartphone />
            {`${capitalize(item.cell)}`}
          </span>
          <span className="icon-text">
            <MdHome />
            {`${capitalize(item.home)}`}
          </span>
          <span className="icon-text">
            <MdEmail />
            {`${item.email || "-"}`}
          </span>
        </div>,
        `${item.apptKey}`,
        <div key={`c3-${idx}`} className="table-cell">
          <span className="icon-text">
            <MdPhoneInTalk />
            {`${moment(item.created).format(`${DATE_FORMAT} hh:mm A`)}`}
          </span>
          <span className="icon-text">
            <MdCalendarToday />
            {`${moment(item.apptSchedTime).format(`${DATE_FORMAT} hh:mm A`)}`}
          </span>
          <span className="icon-text">
            <MdHourglassFull />
            {`Length ${CalcLength(item.startTime, item.endTime)}m`}
          </span>
        </div>,
        capitalize(item.providerText),
        capitalize(item.reason),
        capitalize(item.insName),
        <ShowMoreText key={`c4-${idx}`} text={item.notes} />,
      ])
      .map((item) => item.filter((_, idx) => columnsIndexes.includes(idx)));

  return !error ? (
    <>
      <Table
        colums={userColumns.map((item) => item.label)}
        datas={tableData || []}
        loading={loading}
      />
    </>
  ) : (
    <LoadingError />
  );
};

const DATE_FORMAT_API = "YYYY-MM-DD";
interface WebApptWeekProps {
  coverKey: number;
  authorization: string;
}
export const WebApptWeekTable = ({
  coverKey,
  authorization,
}: WebApptWeekProps) => {
  const [date, setDate] = useState<string>(moment().format(DATE_FORMAT_API));
  const [dateApi, setDateApi] = useState<string>(date);
  const [expanded, setExpanded] = useState<number>();

  const [res, error, loading] = useApi<WebApptWeek[]>(
    MainService.getWebApptWeek(coverKey, authorization, dateApi),
    [coverKey, authorization, dateApi]
  );

  const tableData =
    res &&
    res.map((item, idx) => [
      idx + 1,
      moment(item.date).format(DATE_FORMAT),
      capitalize(String(item.totalCount)),
      <div className="last-icon-table" key={`dropdown-${idx}`}>
        <MdArticle
          onClick={() => {
            setExpanded(expanded === idx ? undefined : idx);
          }}
        />
      </div>,
    ]);

  return !error ? (
    <>
      <div className="appt-week-filters">
        <SingleDatePicker
          label={"Select Start Date"}
          value={date}
          setValue={(d) => setDate(d)}
        />
        <div className="appt-buttons-wrapper">
          <Button label="Get Report" onClick={() => setDateApi(date)} />
        </div>
      </div>
      <div className="web-appointments-results appt-week-results small-table">
        <div className="web-appointments-overflow">
          <Table
            colums={[
              ...WEB_APPT_WEEK_COLUMNS_DEFINE.map((item) => item.label),
              "Show More",
            ]}
            datas={tableData || []}
            loading={loading}
            expandedIdx={expanded}
            dropdownContent={
              res &&
              (expanded || expanded === 0) && (
                <Dropdown res={res} opened={expanded} />
              )
            }
          />
        </div>
      </div>
    </>
  ) : (
    <LoadingError />
  );
};

const Dropdown = ({ opened, res }: { res: WebApptWeek[]; opened: number }) => {
  const total = res.find((_, idx) => idx === opened);
  const data = total?.subList;

  return (
    <>
      {data && (
        <div className="appt-week-drop-wrapper">
          <div className="appt-week-info">
            <span className="title">Source</span>
            {data.map((item, idx) => (
              <span className="info" key={`count-${idx}`}>
                {capitalize(item.source)}
              </span>
            ))}
          </div>
          <div className="appt-week-info">
            <span className="title">Appointments (#)</span>
            {data.map((item, idx) => (
              <span className="info" key={`count-${idx}`}>
                {item.count}
              </span>
            ))}
          </div>
          <div className="appt-week-info">
            <span className="title">Total Web Scheduled Appts (%)</span>
            {data.map((item, idx) => (
              <span className="info" key={`count-${idx}`}>
                {`${
                  total
                    ? ((total.totalCount / 100) * item.count).toFixed(2)
                    : "-"
                } %`}
              </span>
            ))}
          </div>
          <div className="appt-week-info chart">
            <Pie
              data={data.map((item) => ({
                id: item.source,
                value: item.count,
              }))}
              id="id"
              value="value"
              width={160}
              height={160}
              margin={{ top: 20, left: 20, bottom: 20, right: 20 }}
              innerRadius={0.6}
              activeOuterRadiusOffset={8}
              enableArcLabels={false}
              arcLabelsRadiusOffset={0.15}
              arcLabelsSkipAngle={10}
              enableArcLinkLabels={false}
              isInteractive={false}
              theme={{
                labels: {
                  text: {
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                },
              }}
              layers={[
                "arcLinkLabels",
                "arcs",
                (c) => (
                  <>
                    <text
                      x={c.centerX}
                      y={c.centerY - 6}
                      dominantBaseline="middle"
                      textAnchor="middle"
                      color="#272927"
                      fontWeight={"bold"}
                      fontSize={14}
                    >
                      {formatNumber(total.totalCount)}
                    </text>
                    <text
                      x={c.centerX}
                      y={c.centerY + 6}
                      dominantBaseline="middle"
                      textAnchor="middle"
                      color="#5c5c5c"
                      fontSize={8}
                    >
                      Appointments
                    </text>
                  </>
                ),
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};

const ShowMoreText = ({ text }: { text: string }) => {
  const [show, setShow] = React.useState<boolean>(false);
  return (
    <div className="table-cell">
      <span className={`${!show && "closed-text"}`}>
        {text ? text.replace(/<br\/>/g, "\n") : "-"}
      </span>
      {text && (
        <span className="show-more-text" onClick={() => setShow(!show)}>
          {show ? "Hide" : "Show More"}
        </span>
      )}
    </div>
  );
};

const CalcLength = (start: string, end: string) =>
  start && end
    ? moment.duration(moment(end).diff(moment(start))).asMinutes()
    : "-";
