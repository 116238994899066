import React from "react";
import { Button } from "../../../../shared/components/Atoms/Button/Button";
import "./Pagination.css";

interface PaginationProps {
  total?: number;
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
}
export const Pagination = ({
  page,
  pageSize,
  setPage,
  total = 0,
}: PaginationProps) => {
  const pages = Math.ceil(total / pageSize);
  const maxButtons = 5;
  const overflowButtons = 3;
  const array = [...new Array(pages)].map((_, index) => index + 1);
  const overflow = array.length > maxButtons;

  const RenderButton = (index: number) => (
    <div
      key={`pagination-${index}`}
      className={`${page === index && "selected"}`}
    >
      <Button
        label={String(index)}
        onClick={() => page !== index && setPage(index)}
      />
    </div>
  );

  const RenderOther = (
    index: number,
    buttonAfter = false,
    hideOther = false
  ) => (
    <>
      {!buttonAfter && RenderButton(index)}
      {!hideOther && <span className="pagination-other">...</span>}
      {buttonAfter && RenderButton(index)}
    </>
  );

  return pages ? (
    <div className="pagination-container">
      <Button
        label="<"
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      />
      {array
        .slice(
          ...(!overflow
            ? [0, array.length]
            : page === 1
            ? [0, page + overflowButtons]
            : page >= array.length - (overflowButtons - 1)
            ? [array.length - (overflowButtons + 1), array.length]
            : [page - 1, page - 1 + overflowButtons])
        )
        .map((index, pos, subArr) => (
          <React.Fragment key={`page-button-${pos}`}>
            {overflow &&
              pos === 0 &&
              page > 1 &&
              RenderOther(1, false, page === 2)}
            {RenderButton(index)}
            {overflow &&
              pos === subArr.length - 1 &&
              page < array.length - (overflowButtons - 1) &&
              RenderOther(
                array.length,
                true,
                page >= array.length - overflowButtons
              )}
          </React.Fragment>
        ))}
      <Button
        label=">"
        onClick={() => setPage(page + 1)}
        disabled={page === array.length}
      />
    </div>
  ) : (
    <></>
  );
};
