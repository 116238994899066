/* eslint-disable prettier/prettier */
import React from "react";
import "./RecapturedCard.css";
import { BiArrowFromBottom, BiArrowFromTop } from "react-icons/bi";
import { formatNumber } from "../../../../shared/utils/formatNumbers";
import { ContentContainer } from "../../Atoms/ContentContainer/ContentContainer";
import { LineChart } from "../../Charts/LineChart/LineChart";
import { SelectedFilters } from "../../../types/AppTypes";
import { Filters } from "../../../types/ApiTypes";

type RecapturedCardProps = {
  data: ValuesProps[];
  delta: number;
  title: string;
  areaColor: string;
  selected: SelectedFilters;
  filters?: Filters;
};

type ValuesProps = {
  date: string;
  total: number;
  recalled: number;
  value: number;
};

export const RecapturedCard = ({
  data,
  delta,
  title,
  areaColor,
  selected,
  filters,
}: RecapturedCardProps) => {
  const chartData =
    data &&
    data.map((value: ValuesProps) => {
      return {
        x: value.date,
        y: value.value,
      };
    });
  const numberType = Math.sign(delta);

  //console.log('RecapturedCard data---------45', data);

  const maxValue = data.reduce((prev, value) => Math.max(prev, value.value), 0);

  return (
    <ContentContainer padding={20} className="recaptured-card-wrapper">
      <div className="recaptured-card-container">
        <span className="title">{title}</span>
        <span className="recaptured-text">
          <span className="icon">
            {numberType === 1 ? <BiArrowFromBottom /> : <BiArrowFromTop />}
          </span>
          {numberType === 1 ? "+" : ""}
          {`${formatNumber(delta)}% Recaptured`}
        </span>
        <div className="line-chart-container">
          <LineChart
            data={chartData}
            pointValues={data}
            areaColor={areaColor}
            maxValue={maxValue + maxValue / 3}
            selected={selected}
            filters={filters}
          />
        </div>
      </div>
    </ContentContainer>
  );
};
