import React from "react";
import { Filters, FiltersEnum } from "../../../types/ApiTypes";
import { getFilters } from "../../../utils/filterUtils";
import { MultiSelect } from "../../Atoms/MultiSelect/MultiSelect";
import { ChipsContainer } from "../ChipsContainer/ChipsContainer";
import "./PageFilters.css";
import { SelectedFilters } from "../../../types/AppTypes";
import { FILTERS_LABEL } from "../../../constants/Dictionary";

type PageFiltersProps = {
  filters?: Filters;
  title?: string;
  selectedFilters: SelectedFilters;
  setFilter: (filter: SelectedFilters) => void;
};
export const PageFilters = ({
  setFilter,
  filters,
  selectedFilters,
  title,
}: PageFiltersProps) => {
  const handleFilter = (type: FiltersEnum, value: string[]) => {
    const handled = { ...selectedFilters, [type]: value };
    setFilter(handled);
  };

  const handleReset = () => {
    const reset = Object.entries(selectedFilters).reduce(
      (acc, [key]) => (acc = { ...acc, [key]: [] }),
      {}
    ) as SelectedFilters;
    setFilter(reset);
  };

  const handleRemove = (type: string, value: string) => {
    const removed = {
      ...selectedFilters,
      [type]: selectedFilters[type].filter((label: string) => label !== value),
    };
    setFilter(removed);
  };

  const FILTERS = getFilters(filters);

  // comment out for now 7/26/2023
  //const labelsSelect = {
  //  [FiltersEnum.APPT_STATUS]: FILTERS.apptStatus || [],
  //  [FiltersEnum.APPT_TYPE]: FILTERS.apptTypes || [],
  //  [FiltersEnum.LOCATIONS]: FILTERS.locations || [],
  //  [FiltersEnum.PROVIDERS]: FILTERS.providers || [],
  //};

  const labelsSelect = {
    [FiltersEnum.LOCATIONS]: FILTERS.locations || [],
    [FiltersEnum.PROVIDERS]: FILTERS.providers || [],
  };

  return (
    <div className="page-filters-container">
      <div className="select-section">
        <span className="page-filters-title">{title}</span>
        <div className="page-filters-wrapper">
          {filters &&
            Object.values(FiltersEnum).map(
              (item, index) =>
                FILTERS[item] && (
                  <MultiSelect
                    key={`filter-${index}`}
                    label={FILTERS_LABEL[item]}
                    options={FILTERS[item] || []}
                    selected={selectedFilters[item]}
                    setSelected={(val) => handleFilter(item, val)}
                  />
                )
            )}
        </div>
      </div>
      {filters && FILTERS && (
        <ChipsContainer
          data={selectedFilters}
          labels={labelsSelect}
          reset={handleReset}
          remove={(type, val) => handleRemove(type, val)}
        />
      )}
    </div>
  );
};
