import { FiltersEnum } from "./ApiTypes";

export type SelectedFilters = {
  [key in FiltersEnum]: string[];
};
export type SearchFilterSelect = {
  [key in FiltersEnum]: string;
};
export type DateProps = {
  to: string;
  from: string;
};
export enum ApptApi {
  WEB_APPOINTMENTS = "webAppointments",
  PRE_APPOINTMENTS = "remindersPreAppointments",
  RECALLS = "recalls",
  ORDERS = "orderNotifications",
  CANCELLATIONS_RECAPTURED = "cancelled/recapture",
  CANCELLATIONS_APPT = "cancelled/appointment",
  PURCHASE = "purchase/recapture",
  VISITS = "visits",
  NO_SHOW = "noshows",
}
export enum WebApptColumns {
  PATIENT_INFO = "PATIENT_INFO",
  CONTACTS = "CONTACTS",
  APPT_ID = "APPT_ID",
  APPT_DATE = "APPT_DATE",
  LOC_PROV_NAME = "LOC_PROV_NAME",
  APPT_REASON = "APPT_REASON",
  INSURANCE_CARRIER = "INSURANCE_CARRIER",
  NOTE = "NOTE",
}
export enum PreApptColumns {
  DATE_TIME = "DATE_TIME",
  PATIENT_ID = "PATIENT_ID",
  PATIENT_NAME = "PATIENT_NAME",
  LOCAL_PROVIDER_NAME = "LOCAL_PROVIDER_NAME",
  COMM_METHOD = "COMM_METHOD",
  COMM_INFO = "COMM_INFO",
  STATUS = "STATUS",
  RESPONSE = "RESPONSE",
  RECALL_REASON = "RECALL_REASON",
}
export enum OrdersColumns {
  DATE_TIME = "DATE_TIME",
  PATIENT_ID = "PATIENT_ID",
  PATIENT_NAME = "PATIENT_NAME",
  LOCATION_NAME = "LOCATION_NAME",
  COMM_METHOD = "COMM_METHOD",
  COMM_INFO = "COMM_INFO",
  ORDER_NUMBER = "ORDER_NUMBER",
  ITEM_TYPE = "ITEM_TYPE",
  STATUS = "STATUS",
  RESPONSE = "RESPONSE",
}
export enum CancelledApptColumns {
  DATE_TIME = "DATE_TIME",
  CONTACT = "CONTACT",
  LOCAL_PROVIDER_NAME = "LOCAL_PROVIDER_NAME",
  APPT_REASON = "APPT_REASON",
  DOB = "DOB",
}
export enum CancelledRecColumns {
  DATE_TIME = "DATE_TIME",
  PATIENT_ID = "PATIENT_ID",
  PATIENT_NAME = "PATIENT_NAME",
  LOCATION = "LOCATION",
  COMM_METHOD = "COMM_METHOD",
  COMM_INFO = "COMM_INFO",
  STATUS = "STATUS",
  RECALL_REASON = "RECALL_REASON",
}
export enum PurchaseColumns {
  TIME_SEND = "TIME_SEND",
  DATE_TIME = "DATE_TIME",
  PATIENT_ID = "PATIENT_ID",
  PATIENT_NAME = "PATIENT_NAME",
  STATUS = "STATUS",
  LOCAL_PROVIDER_NAME = "LOCAL_PROVIDER_NAME",
}
export enum NoShowColumns {
  DATE_TIME = "DATE_TIME",
  APPT_TYPE = "APPT_TYPE",
  PATIENT_ID = "PATIENT_ID",
  PATIENT_NAME = "PATIENT_NAME",
  APPT_REASON = "APPT_REASON",
}
export enum VisitColumns {
  DATE_TIME = "DATE_TIME",
  APPT_REASON = "APPT_REASON",
  STATUS = "STATUS",
  PATIENT_ID = "PATIENT_ID",
  VISIT_ID = "VISIT_ID",
}
