/* eslint-disable @typescript-eslint/no-unused-vars */
import { EssilorLocation, EssilorProvider } from "./../types/ApiTypes";
import { Filters, FiltersEnum } from "../types/ApiTypes";
import { capitalize } from "../../shared/utils/stringUtils";

type FilterSelect = {
  id: string;
  label: string;
};
const filter = (item: FilterSelect) => item.id && item.id.replace(/ /g, "");
const sort = (a: FilterSelect, b: FilterSelect) =>
  a.label.trim() > b.label.trim() ? 1 : -1;

//const mapNoLabel = (item: string) => ({
//  id: item,
//  label: capitalize(item.trim()),
//});
const mapLabel = ([key, label]: [string, string]) => ({
  id: key,
  label: label.replace(/ /g, "")
    ? capitalize(label.trim())
    : capitalize(key.trim()),
});

export const getFilters = (API?: Filters) => {
  //const apptStatus =
  //  API && API.apptStatus.map(mapNoLabel).sort(sort).filter(filter);
  //const apptTypes =
  //  API && API.apptTypes.map(mapNoLabel).sort(sort).filter(filter);
  const locations =
    API &&
    Object.entries(API.locations).map(mapLabel).sort(sort).filter(filter);
  const providers =
    API &&
    Object.entries(API.providers).map(mapLabel).sort(sort).filter(filter);
  return {
    //[FiltersEnum.APPT_STATUS]: apptStatus,
    //[FiltersEnum.APPT_TYPE]: apptTypes,
    [FiltersEnum.LOCATIONS]: locations,
    [FiltersEnum.PROVIDERS]: providers,
  };
};

export type WebApptFilter = {
  id: string;
  label: string;
}[];

export const getWebApptFilters = (
  prov: EssilorProvider[] | undefined,
  locs: EssilorLocation[] | undefined
): (WebApptFilter | undefined)[] => {
  const provFilter =
    prov &&
    prov.map((item) => ({
      id: String(item.acctKey),
      label: item.providerName,
    }));
  const locFilter =
    locs &&
    locs.map((item) => ({
      id: String(item.locKey),
      label: item.locationName,
    }));

  return [provFilter, locFilter];
};
