import React from "react";
import "./Progress.css";

type ProgressProps = {
  percentage: number | string;
  className?: string;
  color?: string;
};
export const Progress = ({ percentage, className, color }: ProgressProps) => (
  <div className={`progress-container ${className || ""}`}>
    <div
      className="progress-bar"
      style={{ width: `${percentage}%`, backgroundColor: color }}
    />
  </div>
);
