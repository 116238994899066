import React from "react";
import { PatientsEnum, Total } from "../../../types/ApiTypes";
import { formatNumber } from "../../../../shared/utils/formatNumbers";
import { PieChart } from "../../Charts/PieChart/PieChart";
import { ContentContainer } from "../../Atoms/ContentContainer/ContentContainer";
import { Percentage } from "../../Molecules/Percentage/Percentage";
import "./AppointmentChart.css";
import { APPT_LABELS } from "../../../constants/Dictionary";
import { APPT_COLORS } from "../../../constants/AppConstants";

type AppointmentChartProps = {
  data: Total;
};
export const AppointmentChart = ({ data }: AppointmentChartProps) => {
  const CHART_DATA = {
    [PatientsEnum.TOTAL]: data.total.value,
    [PatientsEnum.EXISTING]: data.existing.percentage,
    [PatientsEnum.NEW]: data.newPatients.percentage,
  };
  const PERCENTAGE_DATA = {
    [PatientsEnum.TOTAL]: data.total,
    [PatientsEnum.EXISTING]: data.existing,
    [PatientsEnum.NEW]: data.newPatients,
  };
  return (
    <ContentContainer title="Total Appointments & Number of Recalled Patients">
      <div className="appointment-chart-container">
        <PieChart data={CHART_DATA} />
        <div className="appointment-chart-legend">
          {Object.entries(PERCENTAGE_DATA).map(([key, percentageData]) => (
            <Percentage
              key={key}
              percentage={formatNumber(percentageData.percentage, 2)}
              value={formatNumber(percentageData.value)}
              color={APPT_COLORS[key]}
              description={APPT_LABELS[key]}
            />
          ))}
        </div>
      </div>
    </ContentContainer>
  );
};
