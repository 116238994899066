import React from "react";
import { PatientsEnum, Total } from "../../../types/ApiTypes";
import { formatNumber } from "../../../../shared/utils/formatNumbers";
import { ProgressCard } from "../../Molecules/ProgressCard/ProgressCard";
import { ContentContainer } from "../../Atoms/ContentContainer/ContentContainer";
import "./ProgressReport.css";
import { REPORT_COLORS } from "../../../constants/AppConstants";

type ProgressReportProps = {
  data: Total;
};

export const ProgressReport = ({ data }: ProgressReportProps) => (
  <ContentContainer padding={20} className="progress-report-container">
    <ProgressCard
      percentage={formatNumber(data.existing.percentage, 2)}
      color={REPORT_COLORS[PatientsEnum.TOTAL]}
      prefix="Out Of:"
      description="Successfully Scheduled Patients"
    />
    <ProgressCard
      percentage={formatNumber(data.existingRecalled.percentage, 2)}
      color={REPORT_COLORS[PatientsEnum.RECALLED]}
      description="Were Successfully Recaptured Via Recall"
    />
    <ProgressCard
      percentage={formatNumber(data.touches, 2)}
      color={REPORT_COLORS["touches"]}
      description="Average Number Of Unique Touches"
      isNumber
    />
  </ContentContainer>
);
