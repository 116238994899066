import React from "react";

import { StoryFn, Meta } from "@storybook/react";

import { Button as Component } from "../components/Atoms/Button/Button";
import "../index.css";

export default {
  title: "Components/Atoms",
  component: Component,
  args: {
    label: "Button",
  },
  argTypes: {},
} as Meta<typeof Component>;

export const Button: StoryFn<typeof Component> = (args) => (
  <Component {...args} />
);
