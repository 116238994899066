import React from "react";
import { DEFAULT_DATE } from "../../../constants/AppConstants";
import { DateProps } from "../../../types/AppTypes";
import { Button } from "../../../../shared/components/Atoms/Button/Button";
import "./DatePicker.css";

type DatePickerProps = {
  search: (newDate: DateProps) => void;
  defaultDate?: DateProps;
};

export const DatePicker = ({ search, defaultDate }: DatePickerProps) => {
  const [date, setDate] = React.useState(defaultDate || DEFAULT_DATE);

  return (
    <div className="date-picker-container">
      <div className="date-picker-wrapper">
        <span
          className={`date-label ${(date.to || date.from) && "clickable"}`}
          onClick={() => {
            setDate(defaultDate || DEFAULT_DATE);
            search(defaultDate || DEFAULT_DATE);
          }}
        >
          {date.to || date.from ? "Reset" : "Date Range"}
        </span>
        <div className="input-container first">
          <span className="input-label">From:</span>
          <input
            type="date"
            value={date.from}
            onChange={(e) => setDate({ ...date, from: e.target.value })}
          />
        </div>
        <div className="input-container">
          <span className="input-label">To:</span>
          <input
            type="date"
            value={date.to}
            onChange={(e) => setDate({ ...date, to: e.target.value })}
          />
        </div>
        <Button halfLeft label="Search" onClick={() => search(date)} />
      </div>
      <p className="date-picker-text">
        Data represented was pulled from date range:{" "}
        {date.from && date.to && `${date.from} to ${date.to}`}
        {!date.from && !date.to && `last 6 months`}
      </p>
    </div>
  );
};

interface SingleDatePickerProps {
  label: string;
  value: string;
  setValue: (date: string) => void;
}
export const SingleDatePicker = ({
  label,
  value,
  setValue,
}: SingleDatePickerProps) => (
  <div className="single-date-container">
    <p className="single-date-label">{label}</p>
    <input
      value={value}
      type="date"
      className="single-date-input"
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
);
