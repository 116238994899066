import {
  CancelledApptColumns,
  CancelledRecColumns,
  NoShowColumns,
  OrdersColumns,
  PreApptColumns,
  PurchaseColumns,
  VisitColumns,
  WebApptColumns,
} from "./../types/AppTypes";
import React from "react";
import {
  CANCELLATIONS_COLUMNS_DEFINE,
  CANCELLATIONS_REC_COLUMNS_DEFINE,
  NO_SHOW_COLUMNS_DEFINE,
  ORDERS_COLUMNS_DEFINE,
  PRE_APPT_COLUMNS_DEFINE,
  PURCHASE_COLUMNS_DEFINE,
  VISIT_COLUMNS_DEFINE,
  WEB_APPT_COLUMNS_DEFINE,
} from "../constants/AppConstants";
import MainService from "../services/MainService";
import { ApptApi } from "../types/AppTypes";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ColumnEnum: { [key in ApptApi]: any } = {
  [ApptApi.WEB_APPOINTMENTS]: WebApptColumns,
  [ApptApi.PRE_APPOINTMENTS]: PreApptColumns,
  [ApptApi.RECALLS]: PreApptColumns,
  [ApptApi.ORDERS]: OrdersColumns,
  [ApptApi.CANCELLATIONS_RECAPTURED]: CancelledRecColumns,
  [ApptApi.CANCELLATIONS_APPT]: CancelledApptColumns,
  [ApptApi.PURCHASE]: PurchaseColumns,
  [ApptApi.VISITS]: VisitColumns,
  [ApptApi.NO_SHOW]: NoShowColumns,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ColumnDefine: { [key in ApptApi]: { id: any; label: string }[] } =
  {
    [ApptApi.WEB_APPOINTMENTS]: WEB_APPT_COLUMNS_DEFINE,
    [ApptApi.PRE_APPOINTMENTS]: PRE_APPT_COLUMNS_DEFINE,
    [ApptApi.RECALLS]: PRE_APPT_COLUMNS_DEFINE,
    [ApptApi.ORDERS]: ORDERS_COLUMNS_DEFINE,
    [ApptApi.CANCELLATIONS_RECAPTURED]: CANCELLATIONS_REC_COLUMNS_DEFINE,
    [ApptApi.CANCELLATIONS_APPT]: CANCELLATIONS_COLUMNS_DEFINE,
    [ApptApi.PURCHASE]: PURCHASE_COLUMNS_DEFINE,
    [ApptApi.VISITS]: VISIT_COLUMNS_DEFINE,
    [ApptApi.NO_SHOW]: NO_SHOW_COLUMNS_DEFINE,
  };

export const useDynamicColumns = <T>(
  coverKey: number,
  JWT: string,
  apiEnum: ApptApi,
  columnsInit?: T[]
) => {
  const [columns, setColumnsPref] = React.useState<T[]>([]);

  const setColumns = (newCols: T[]) => {
    setColumnsPref(newCols);
    MainService.setApptColumns<T>(coverKey, JWT, newCols, apiEnum).subscribe();
  };

  const ALL_COLUMNS = ColumnDefine[apiEnum].map((item) => item.id);

  const filteredColumns =
    columnsInit &&
    columnsInit.filter((apiCol) =>
      Object.values(ColumnEnum[apiEnum]).includes(apiCol)
    );

  React.useEffect(() => {
    if (columns.length === 0) {
      columnsInit && setColumns(ALL_COLUMNS);
    }
  }, [columns]);

  React.useEffect(() => {
    setColumnsPref(filteredColumns || []);
  }, [columnsInit]);

  return [
    columns as unknown as string[],
    setColumns as unknown as (newCols: string[]) => void,
  ] as [string[], (newCols: string[]) => void];
};
