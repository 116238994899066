import React from "react";
import "./Tabs.css";
import {
  TabsList as SharedTabList,
  Tab as SharedTab,
  TabsListProps,
  TabProps,
} from "../../../../shared/components/Molecules/Tabs/Tabs";

export const TabsList = (props: TabsListProps) => <SharedTabList {...props} />;
export const Tab = (props: TabProps) => <SharedTab {...props} />;
