import React from "react";
import { Loading } from "../../Atoms/Loading/Loading";
import { NoDataFound } from "../../Atoms/LoadingError/LoadingError";
import "./Table.css";

export interface TableProps {
  title?: string;
  subTitle?: string;
  colums: React.ReactNode[];
  datas: React.ReactNode[][];
  footer?: React.ReactNode;
  loading?: boolean;
  expandedIdx?: number;
  dropdownContent?: React.ReactNode;
}

export const Table = ({
  title,
  subTitle,
  footer,
  datas,
  colums,
  loading,
  expandedIdx,
  dropdownContent,
}: TableProps) => {
  return (
    <div
      className={`table-container ${loading ? "loading" : ""} ${
        !loading && datas.length === 0 ? "no-data" : ""
      }`}
    >
      {title && (
        <div className="table-title">
          <p>
            {title}
            <span>{subTitle && ` - ${subTitle}`}</span>
          </p>
        </div>
      )}
      {loading && <Loading full />}
      {!loading && (
        <>
          {datas.length > 0 && (
            <div className="colums-title-container">
              {colums.map((label, idx) => {
                return (
                  <div key={`${idx}-${label}`} className="colum-title-label">
                    <span>{label || "-"}</span>
                  </div>
                );
              })}
            </div>
          )}
          <div className="body-table-container">
            {datas.map((row, idx) => {
              return (
                <React.Fragment key={`row-table-${idx}`}>
                  <div
                    className="row-table-container"
                    style={{
                      borderBottom:
                        expandedIdx || expandedIdx === 0 ? "none" : "",
                    }}
                  >
                    {row.map((item, index) => {
                      return (
                        <div
                          className="data-table-container"
                          key={`data-table-${index}`}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  {idx === expandedIdx && (
                    <div className="row-table-dropdown">{dropdownContent}</div>
                  )}
                </React.Fragment>
              );
            })}
            {datas.length === 0 && <NoDataFound />}
          </div>
          {datas.length > 0 && (
            <div className={`footer-container ${!footer && "no-padding"}`}>
              {footer}
            </div>
          )}
        </>
      )}
    </div>
  );
};
