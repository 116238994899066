import React from "react";
import { Progress } from "../../Atoms/Progress/Progress";
import "./Percentage.css";

type PercentageProps = {
  percentage: number | string;
  icon?: JSX.Element;
  color?: string;
  progress?: boolean;
  value: number;
  description: string;
};
export const Percentage = ({
  percentage = 0,
  color = "#a2d4f5",
  icon,
  progress,
  value = 0,
  description = "Description",
}: PercentageProps) => (
  <div className="percentage-container">
    <div className="percentage-row">
      {!icon && (
        <div className="percentage-color" style={{ background: color }} />
      )}
      {icon && <span>{icon}</span>}
      <div className="percentage-text-container">
        <div className="percentage-row">
          <span className="percentage-text">{`${
            !progress ? percentage : value
          }${!progress ? "%" : ""}`}</span>
        </div>
        <div className="percentage-row">
          <span className="percentage-description">{`${description}${
            !progress ? ": " : ""
          }`}</span>
          {!progress && <span className="percentage-value">{value}</span>}
        </div>
        {progress && (
          <Progress
            percentage={percentage}
            color={color}
            className="percentage-progress"
          />
        )}
      </div>
    </div>
    <div className="percentage-divider" />
  </div>
);
