import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import "./Select.css";
import "../MultiSelect/MultiSelect.css";
import { MdCheck } from "react-icons/md";

interface SelectProps {
  label: string;
  options: {
    id: string;
    label: string;
  }[];
  selected?: string;
  setSelected: (val: string) => void;
}
export const Select = ({
  label,
  options,
  selected,
  setSelected,
}: SelectProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      });
      return () => {
        document.removeEventListener("mousedown", () => null);
      };
    }
  }, [open]);

  const selectedLabel =
    selected && options.find((item) => item.id === selected)?.label;

  return (
    <div className="select-container">
      <p className="select-text">{label}</p>
      <div ref={ref} className={`multiselect-container ${open && "open"}`}>
        <div className="multiselect-button" onClick={() => setOpen(!open)}>
          <div className="label-text truncate">
            <label>{selectedLabel || label}</label>
          </div>
          <IoIosArrowDown className="icon-multiselect animate" />
        </div>
        <div className="multiselect-panel">
          <div className="scroll">
            {options.map((option, index) => (
              <div
                className="multiselect-option"
                key={`select-opt-${option.id}-${index}`}
                onClick={() => setSelected(option.id)}
              >
                <label>{option.label}</label>
                {selected === option.id && <MdCheck />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
