import React from "react";
import { Loading } from "../../Atoms/Loading/Loading";
import "./Tabs.css";

export type TabsListProps = {
  tabs: TabId[];
  children?: JSX.Element[];
  selected: string | number;
  setTab: (newTab: string | number) => void;
  loading?: boolean;
};
export type TabId = {
  id: string | number;
  label: string;
};
export type TabProps = {
  index: string | number;
  children?: React.ReactNode;
  selected: string | number;
};
export const TabsList = ({
  tabs,
  selected,
  children,
  setTab,
  loading,
}: TabsListProps) => (
  <div className="tabs-list-container">
    <div className="tabs-list-header">
      {tabs.map((item) => (
        <span
          key={`option-${item.id}`}
          className={`tabs-list-option ${selected === item.id && "selected"}`}
          onClick={() => setTab(item.id)}
        >
          {item.label}
        </span>
      ))}
    </div>
    <div className="tabs-list-body">
      {!loading &&
        children &&
        children.map((child) => child.props.index === selected && child)}
      {loading && <Loading full />}
    </div>
  </div>
);
export const Tab = ({ index, children, selected }: TabProps) => (
  <div key={index}>{index === selected && children}</div>
);
