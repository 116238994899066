import React from "react";
import "./PageTitle.css";

type PageTitleProps = {
  title: string;
  textClassName?: string;
};
export const PageTitle = ({ title, textClassName }: PageTitleProps) => (
  <span className={`page-title-text ${textClassName}`}>{title}</span>
);
