import React from "react";
import { Button } from "../../../../shared/components/Atoms/Button/Button";
import { ColumnDefine } from "../../../hooks/useDynamicColumns";
import { ApptApi, DateProps } from "../../../types/AppTypes";
import { SingleDatePicker } from "../../Atoms/DatePicker/DatePicker";
import { MultiSelectWithLabel } from "../../Atoms/MultiSelect/MultiSelect";
import { Select } from "../../Atoms/Select/Select";
import "./ApptFilters.css";

export interface ApptFilters {
  type?: string;
  commMethods?: string[];
  providers?: string[];
  locations?: string[];
}
export interface FiltersToShow {
  providers?: { id: string; label: string }[];
  locations?: { id: string; label: string }[];
  types?: { id: string; label: string }[];
  commMethods?: { id: string; label: string }[];
}
type ApptFiltersProps = {
  setFilters: (type: string, val: string | string[]) => void;
  setDate: (type: "from" | "to", val: string) => void;
  filters: ApptFilters;
  providers?: { id: string; label: string }[];
  locations?: { id: string; label: string }[];
  types?: { id: string; label: string }[];
  commMethods?: { id: string; label: string }[];
  dates: DateProps;
  columns: string[];
  setColumns: (newCols: string[]) => void;
  handleShowReport: () => void;
  handleQuickReport?: () => void;
  columnsDefine: ApptApi;
};
export const ApptFilters = ({
  types,
  locations,
  providers,
  filters,
  commMethods,
  dates,
  setDate,
  setFilters,
  columns,
  setColumns,
  handleShowReport,
  handleQuickReport,
  columnsDefine,
}: ApptFiltersProps) => {
  const getFilterLabel = (
    options: { id: string; label: string }[],
    field: string
  ) =>
    options
      .filter((item) => (filters[field] as string[]).includes(item.id))
      .map((item) => item.label)
      .join(", ");

  const ReturnDatePicker = () => (
    <>
      <SingleDatePicker
        label={"Select Start Date"}
        value={dates.from}
        setValue={(date) => setDate("from", date)}
      />
      <SingleDatePicker
        label={"Select End Date"}
        value={dates.to}
        setValue={(date) => setDate("to", date)}
      />
    </>
  );

  return (
    <div className="web-appointments-filters">
      {types && (
        <Select
          label={"Select Report Type"}
          options={types}
          selected={filters.type}
          setSelected={(val) => setFilters("type", val)}
        />
      )}
      {Object.keys(filters).length >= 4 || Object.keys(filters).length <= 2 ? (
        <div className="appt-date-picker-container">{ReturnDatePicker()}</div>
      ) : (
        ReturnDatePicker()
      )}
      {locations && (
        <MultiSelectWithLabel
          label={"Select Locations"}
          options={locations}
          selected={filters.locations || []}
          setSelected={(val) => setFilters("locations", val)}
          valueLabel={getFilterLabel(locations, "locations")}
          showSelectAll
        />
      )}
      {providers && (
        <MultiSelectWithLabel
          label={"Select Providers"}
          options={providers}
          selected={filters.providers || []}
          setSelected={(val) => setFilters("providers", val)}
          valueLabel={getFilterLabel(providers, "providers")}
          showSelectAll
        />
      )}
      {commMethods && (
        <MultiSelectWithLabel
          label={"Select Communication Method"}
          options={commMethods}
          selected={filters.commMethods || []}
          setSelected={(val) => setFilters("commMethods", val)}
          valueLabel={getFilterLabel(commMethods, "commMethods")}
          showSelectAll
        />
      )}
      <div
        className="web-appointments-button-container"
        style={{
          gridColumn: `span ${lastRowSpan(Object.keys(filters).length)}`,
        }}
      >
        <MultiSelectWithLabel
          label={"Table Columns"}
          options={ColumnDefine[columnsDefine]}
          selected={columns}
          setSelected={setColumns}
          valueLabel={String(columns.length)}
        />
        <div className="appt-buttons-wrapper">
          {handleQuickReport && (
            <Button label="Quick Report" onClick={() => handleQuickReport()} />
          )}
          <Button label="Get Report" onClick={() => handleShowReport()} />
        </div>
      </div>
    </div>
  );
};

const lastRowSpan = (numOfFilters: number) => {
  switch (true) {
    case numOfFilters > 0 && numOfFilters <= 2:
      return 3;
    case numOfFilters > 2 && numOfFilters <= 4:
      return 1;
    case numOfFilters > 4:
      return 3 - ((numOfFilters + 1) % 6);
    default:
      return 3;
  }
};
