import moment from "moment";
import React from "react";
import { LoadingError } from "../../components";
import {
  CANCELLATIONS_COLUMNS_DEFINE,
  CANCELLATIONS_REC_COLUMNS_DEFINE,
  DATE_FORMAT,
} from "../../constants/AppConstants";
import { CancelledAppt, CancelledRecapture } from "../../types/ApiTypes";
import { Table } from "../../components/Molecules/Table/Table";
import { capitalize } from "../../../shared/utils/stringUtils";
import {
  MdAssignmentLate,
  MdAssignmentTurnedIn,
  MdEmail,
  MdHome,
  MdOutlineSmartphone,
} from "react-icons/md";

export const CANCELLATION_TABS = [
  {
    id: 0,
    label: "Cancellation Appointment Report",
  },
  {
    id: 1,
    label: "Cancellation Recapture Report",
  },
];

export const CancelledRecTable = ({
  res,
  loading,
  columns,
  error,
}: {
  res?: CancelledRecapture[];
  loading?: boolean;
  columns: string[];
  error?: boolean;
}) => {
  const userColumns = CANCELLATIONS_REC_COLUMNS_DEFINE.map((item, idx) => ({
    ...item,
    idx,
  })).filter((item) => columns.includes(item.id));
  const columnsIndexes = userColumns.map((item) => item.idx);
  const tableData =
    res &&
    res
      .map((item) => [
        moment(item.commAt).format(`${DATE_FORMAT} hh:mm A`),
        capitalize(item.patientID),
        `${capitalize(item.firstName)} ${capitalize(item.lastName)}`,
        capitalize(item.locationName),
        capitalize(item.commModality),
        capitalize(item.commTo),
        capitalize(item.commStatusText),
        capitalize(item.reason),
      ])
      .map((item) => item.filter((_, idx) => columnsIndexes.includes(idx)));

  return !error ? (
    <>
      <Table
        colums={userColumns.map((item) => item.label)}
        datas={tableData || []}
        loading={loading}
      />
    </>
  ) : (
    <LoadingError />
  );
};

export const CancelledApptTable = ({
  res,
  loading,
  columns,
  error,
}: {
  res?: CancelledAppt[];
  loading?: boolean;
  columns: string[];
  error?: boolean;
}) => {
  const userColumns = CANCELLATIONS_COLUMNS_DEFINE.map((item, idx) => ({
    ...item,
    idx,
  })).filter((item) => columns.includes(item.id));
  const columnsIndexes = userColumns.map((item) => item.idx);
  const tableData =
    res &&
    res
      .map((item, idx) => [
        <div key={`c1-${idx}`} className="table-cell">
          <span className="icon-text">
            <MdAssignmentTurnedIn />
            {`${moment(item.apptStartDate).format(`${DATE_FORMAT} hh:mm A`)}`}
          </span>
          <span className="icon-text">
            <MdAssignmentLate />
            {`${moment(item.cancelledDate).format(`${DATE_FORMAT} hh:mm A`)}`}
          </span>
        </div>,
        <div key={`c2-${idx}`} className="table-cell">
          <span className="icon-text">
            <MdOutlineSmartphone />
            {`${capitalize(item.cellPhone)}`}
          </span>
          <span className="icon-text">
            <MdHome />
            {`${capitalize(item.homePhone)}`}
          </span>
          <span className="icon-text">
            <MdEmail />
            {`${item.email || "-"}`}
          </span>
        </div>,
        <div key={`c3-${idx}`} className="table-cell">
          <span>{capitalize(item.locationName)}</span>
          <span>{capitalize(item.providerName)}</span>
        </div>,
        capitalize(item.apptReason),
        moment(item.patientDOB).format(DATE_FORMAT),
      ])
      .map((item) => item.filter((_, idx) => columnsIndexes.includes(idx)));

  return !error ? (
    <>
      <Table
        colums={userColumns.map((item) => item.label)}
        datas={tableData || []}
        loading={loading}
      />
    </>
  ) : (
    <LoadingError />
  );
};
