/* eslint-disable prettier/prettier */
import React from "react";
import {
  Filters,
  FiltersEnum,
  Modality,
  Recalled,
  Total,
} from "../../types/ApiTypes";
import MainService from "../../services/MainService";
import { DateProps, SelectedFilters } from "../../types/AppTypes";
import {
  LoadingError,
  NoDataFound,
} from "../../../shared/components/Atoms/LoadingError/LoadingError";
import { useApi } from "../../../shared/hooks/useApi";
import { MODALITY_COLOR } from "../../constants/AppConstants";
import { AppointmentChart } from "../../components/Organisms/AppointmentChart/AppointmentChart";
import { PercentageReport } from "../../components/Organisms/PercentageReport/PercentageReport";
import { ProgressReport } from "../../components/Organisms/ProgressReport/ProgressReport";
import { Legend } from "../../components/Molecules/Legend/Legend";
import { EfficiencyCard } from "../../components/Molecules/EfficiencyCard/EfficiencyCard";
import { RecapturedCard } from "../../components/Molecules/RecapturedCard/RecapturedCard";

import "./RecallReport.css";
import { Loading } from "../../../shared/components/Atoms/Loading/Loading";
import { MODALITY_LABEL } from "../../constants/Dictionary";
import { AjaxResponse } from "rxjs/ajax";

export const RECALL_REPORT_TABS = [
  {
    id: 0,
    label: "Total Appointments",
  },
  {
    id: 1,
    label: "Recalled Patients",
  },
  {
    id: 2,
    label: "Recall Modality",
  },
];

export const TotalAppt = ({
  selected,
  dateFilter,
  coverKey,
}: {
  selected: SelectedFilters;
  dateFilter: DateProps;
  coverKey: string;
}) => {
  const [API, ERROR_API, IS_LOADING] = useApi<AjaxResponse<Total>>(
    // comment out for now 07/26/2023
    //MainService.getRecallTotal(
    //  selected[FiltersEnum.APPT_STATUS],
    //  selected[FiltersEnum.APPT_TYPE],
    //  selected[FiltersEnum.LOCATIONS],
    //  selected[FiltersEnum.PROVIDERS],
    //  dateFilter.to,
    //  dateFilter.from,
    //  coverKey
    //),
    MainService.getRecallTotal(
      selected[FiltersEnum.LOCATIONS],
      selected[FiltersEnum.PROVIDERS],
      dateFilter.to,
      dateFilter.from,
      coverKey
    ),
    [selected, dateFilter]
  );

  //console.log('API-------66', API, ERROR_API, IS_LOADING);

  return (
    <>
      {API && (
        <div className="content-body">
          <AppointmentChart data={API.response} />
          <div className="prog-perc-container">
            <ProgressReport data={API.response} />
            <PercentageReport data={API.response} />
          </div>
        </div>
      )}
      {IS_LOADING && <Loading full />}
      {!API && ERROR_API && <LoadingError />}
      {!IS_LOADING && !ERROR_API && !API && <NoDataFound />}
    </>
  );
};

export const RecalledPat = ({
  selected,
  dateFilter,
  coverKey,
}: {
  selected: SelectedFilters;
  dateFilter: DateProps;
  coverKey: string;
}) => {
  const [API, ERROR_API, IS_LOADING] = useApi<AjaxResponse<Recalled>>(
    // comment out for now 07/26/2023
    //MainService.getRecallRecalled(
    //  selected[FiltersEnum.APPT_STATUS],
    //  selected[FiltersEnum.APPT_TYPE],
    //  selected[FiltersEnum.LOCATIONS],
    //  selected[FiltersEnum.PROVIDERS],
    //  dateFilter.to,
    //  dateFilter.from,
    //  coverKey
    //),
    MainService.getRecallRecalled(
      selected[FiltersEnum.LOCATIONS],
      selected[FiltersEnum.PROVIDERS],
      dateFilter.to,
      dateFilter.from,
      coverKey
    ),
    [selected, dateFilter]
  );

  //console.log('API-------108', API, ERROR_API, IS_LOADING);

  return (
    <>
      {API && (
        <>
          <div className="page-content-legend">
            <span className="page-title">Recall Efficency</span>
            <Legend
              data={[
                { color: "rgb(56, 117, 191)", label: "Existing Patients" },
                { color: "rgb(246, 196, 129)", label: "Recalled Patients" },
              ]}
            />
          </div>
          <div className="content-wrap recalled-patients">
            {API.response.slice(-6).map((item, idx) => (
              <EfficiencyCard
                key={item.date}
                id={`card-eff-${idx}`}
                data={item}
              />
            ))}
          </div>
        </>
      )}
      {IS_LOADING && <Loading full />}
      {ERROR_API && <LoadingError />}
      {!IS_LOADING && !ERROR_API && (!API || API.response.length === 0) && (
        <NoDataFound />
      )}
    </>
  );
};

export const RecModality = ({
  selected,
  dateFilter,
  coverKey,
  filters,
}: {
  selected: SelectedFilters;
  dateFilter: DateProps;
  coverKey: string;
  filters?: Filters;
}) => {
  const [API, ERROR_API, IS_LOADING] = useApi<AjaxResponse<Modality>>(
    // comment out for now 07/26/2023
   //MainService.getRecallModality(
   //   selected[FiltersEnum.APPT_STATUS],
   //   selected[FiltersEnum.APPT_TYPE],
   //   selected[FiltersEnum.LOCATIONS],
   //   selected[FiltersEnum.PROVIDERS],
   //   dateFilter.to,
   //   dateFilter.from,
   //   coverKey
   // ),
    MainService.getRecallModality(
      selected[FiltersEnum.LOCATIONS],
      selected[FiltersEnum.PROVIDERS],
      dateFilter.to,
      dateFilter.from,
      coverKey
    ),
    [selected, dateFilter]
  );

  //console.log('API-------167', API, ERROR_API, IS_LOADING);

  return (
    <>
      {API && (
        <>
          <div className="page-content-legend">
            <span className="page-title">Modality Usage</span>
          </div>
          <div className="content-wrap">
            {Object.entries(API.response).map(([key, value]) => (
              <RecapturedCard
                key={key}
                data={value.values}
                delta={value.delta}
                title={MODALITY_LABEL[key]}
                areaColor={MODALITY_COLOR[key]}
                selected={selected}
                filters={filters}
              />
            ))}
          </div>
        </>
      )}
      {IS_LOADING && <Loading full />}
      {ERROR_API && <LoadingError />}
      {!IS_LOADING && !ERROR_API && (!API || Object.keys(API).length === 0) && (
        <NoDataFound />
      )}
    </>
  );
};
