/* eslint-disable prettier/prettier */
import React from "react";
import { FiltersEnum } from "../../../types/ApiTypes";
import { SelectedFilters } from "../../../types/AppTypes";
import { capitalize } from "../../../../shared/utils/stringUtils";
import { Chip } from "../../Atoms/Chip/Chip";
import "./ChipsContainer.css";

type ChipsContainerProps = {
  data: SelectedFilters;
  reset: () => void;
  remove: (type: string, val: string) => void;
  labels: labelsSelectProps;
};

type labelsSelectProps = {
  //[FiltersEnum.APPT_STATUS]: labelsIdProps[];
  //[FiltersEnum.APPT_TYPE]: labelsIdProps[];
  [FiltersEnum.LOCATIONS]: labelsIdProps[];
  [FiltersEnum.PROVIDERS]: labelsIdProps[];
};

type labelsIdProps = {
  id: string;
  label: string;
};

export const ChipsContainer = ({
  data,
  reset,
  remove,
  labels,
}: ChipsContainerProps) => {
  return (
    <div className="chips-container">
      {Object.entries(data).map(([key, item]) =>
        item.map((id, idx) => {
          const filterOption: labelsIdProps = labels[key].find(
            (obj: labelsIdProps) => obj.id === id
          );
          return (
            <Chip
              key={`${id}${idx}`}
              label={filterOption ? filterOption.label : capitalize(id.trim())}
              onClick={() => remove(key, id)}
            />
          );
        })
      )}
    {/*  {(data.locations.length !== 0 ||*/}
    {/*    data.providers.length !== 0 ||*/}
    {/*    data.apptStatus.length !== 0 ||*/}
    {/*    data.apptTypes.length !== 0) && (*/}
    {/*    <div className="reset-button" onClick={reset}>*/}
    {/*      Reset*/}
    {/*    </div>*/}
    {/*  )}*/}
        {(data.locations.length !== 0 ||
        data.providers.length !== 0 ) && (
        <div className="reset-button" onClick={reset}>
          Reset
        </div>
      )}

    </div>
  );
};
