import React from "react";
import { FaFileCsv } from "react-icons/fa";
import { MdSearch } from "react-icons/md";
import { AjaxError } from "rxjs/ajax";
import { Button } from "../../../../shared/components/Atoms/Button/Button";
import { capitalize } from "../../../../shared/utils/stringUtils";
import MainService from "../../../services/MainService";
import { ApptFile } from "../../../types/ApiTypes";
import { ApptApi } from "../../../types/AppTypes";
import Alert from "../../Atoms/Alert/Alert";
import "./ApptActions.css";

interface ApptActionsProps<T> {
  search: string;
  setSearch: (phrase: string) => void;
  coverKey: number;
  body: T;
  apiName: ApptApi;
  authorization: string;
}
export const ApptActions = ({
  search,
  setSearch,
  coverKey,
  body,
  apiName,
  authorization,
}: ApptActionsProps<typeof body>) => {
  const downloadFile = (file: ApptFile) => {
    Alert.showAlertInfo(
      "Export of the requested file is in progress, downloading will begin shortly."
    );
    const subscriber$ = MainService.getAppt<typeof body, Blob>(
      coverKey,
      authorization,
      { ...body, pageSize: 0, pageStart: 0 },
      apiName,
      file !== ApptFile.CSV ? file : ApptFile.XLSX
    ).subscribe((res) => {
      if (
        ((res as AjaxError).status &&
          (res as AjaxError).status !== 200 &&
          (res as AjaxError).status !== 201) ||
        !res
      ) {
        Alert.showAlertInfo(
          "An error occurred while downloading the requested file, please try again later."
        );
      } else {
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(res as Blob);
        link.href = url;
        link.setAttribute("download", `${capitalize(apiName)}_Export.${file}`);
        document.body.appendChild(link);
        link.click();
      }
      subscriber$.unsubscribe();
    });
  };

  return (
    <div className="web-appointments-actions">
      <div className="web-appointments-input">
        <MdSearch />
        <input
          type="text"
          placeholder="Filter the Table"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {/*<Button
        label="Export to PDF"
        onClick={() => downloadFile(ApptFile.PDF)}
        iconLeft={<FaFilePdf />}
      />
      <Button
        label="Export to Excel"
        onClick={() => downloadFile(ApptFile.XLSX)}
        iconLeft={<FaFileExcel />}
      />*/}
      <Button
        label="Export to CSV"
        onClick={() => downloadFile(ApptFile.CSV)}
        iconLeft={<FaFileCsv />}
      />
    </div>
  );
};
