import { ResponsivePie } from "@nivo/pie";
import React from "react";
import { formatNumber } from "../../../../shared/utils/formatNumbers";
import "./PieChart.css";

type PieChartProps = {
  data: {
    total: number;
    existing: number;
    newPatients: number;
  };
};
export const PieChart = ({ data }: PieChartProps) => {
  const CHART_DATA = [
    { id: "ex", value: data.existing, color: "#f6c481" },
    { id: "newPatients", value: data.newPatients, color: "#335888" },
  ];
  return (
    <div className="pie-chart-wrapper">
      <ResponsivePie
        data={CHART_DATA}
        id="id"
        value="value"
        margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
        innerRadius={0.55}
        activeOuterRadiusOffset={8}
        enableArcLabels={false}
        arcLabelsRadiusOffset={0.15}
        arcLabelsSkipAngle={10}
        enableArcLinkLabels={false}
        isInteractive={false}
        theme={{
          labels: {
            text: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          },
        }}
        colors={(d) => d.data.color}
        layers={[
          "arcLinkLabels",
          "arcs",
          (c) => (
            <circle
              r={c.radius}
              cx={c.centerX}
              cy={c.centerY}
              stroke="#79aada"
              strokeWidth={8}
              fill="none"
            />
          ),
          (c) => (
            <>
              <text
                x={c.centerX}
                y={c.centerY - 6}
                dominantBaseline="middle"
                textAnchor="middle"
                color="#272927"
                fontWeight={"bold"}
                fontSize={20}
              >
                {formatNumber(data.total)}
              </text>
              <text
                x={c.centerX}
                y={c.centerY + 10}
                dominantBaseline="middle"
                textAnchor="middle"
                color="#5c5c5c"
                fontSize={12}
              >
                Appointments
              </text>
            </>
          ),
        ]}
      />
    </div>
  );
};
