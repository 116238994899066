import React from "react";
import "./ContentContainer.css";

type ContentContainerProps = {
  children: React.ReactNode;
  className?: string;
  title?: string;
  padding?: number | string;
};
export const ContentContainer = ({
  children,
  className,
  title,
  padding = 30,
}: ContentContainerProps) => (
  <div
    className={`content-container-wrapper ${className || ""}`}
    style={{ padding: padding }}
  >
    {title && <span className="content-container-title">{title}</span>}
    <div className={`content-container-body ${!title ? "no-padding" : ""}`}>
      {children}
    </div>
  </div>
);
