import { ApptApi } from "./../types/AppTypes";
import React from "react";
import { useApi } from "../../shared/hooks/useApi";
import MainService from "../services/MainService";
import { EssilorLocation, EssilorProvider } from "../types/ApiTypes";
import { DateProps } from "../types/AppTypes";
import { WebApptFilter, getWebApptFilters } from "../utils/filterUtils";
import { useDynamicColumns } from "./useDynamicColumns";

interface FilterProps {
  dates: DateProps;
  setDate: (field: "from" | "to", date: string) => void;
  columns: string[];
  setColumns: (newCols: string[]) => void;
  columnsDefine: ApptApi;
}
export interface AppReportHook<TResponse> {
  dates: DateProps;
  setDate: (field: "from" | "to", date: string) => void;
  setDateState: (dates: DateProps) => void;
  locations: WebApptFilter | undefined;
  providers: WebApptFilter | undefined;
  errorFilters: boolean;
  loadingFilters: boolean;
  apptData: TResponse | undefined;
  loadingData: boolean;
  errorData: boolean;
  columns: string[];
  setColumns: (newCols: string[]) => void;
  FiltersProps: FilterProps;
}

export const useApptReports = <TResponse, TColumns, TBody>(
  coverKey: number,
  authorization: string,
  apiType: ApptApi,
  apiBody: TBody
): AppReportHook<TResponse> => {
  const [dates, setDates] = React.useState<DateProps>({
    from: "",
    to: "",
  });
  const setDate = (field: "from" | "to", date: string) =>
    setDates({ ...dates, [field]: date });

  const [loc, errorLoc, loadingLoc] = useApi<EssilorLocation[]>(
    MainService.getApptLocations(coverKey, authorization),
    [coverKey, authorization]
  );

  const [prov, errorProv, loadingProv] = useApi<EssilorProvider[]>(
    MainService.getApptProviders(coverKey, authorization),
    [coverKey, authorization]
  );

  const [providers, locations] = getWebApptFilters(prov, loc);

  const [res, errorRes, loadingRes] = useApi<TResponse>(
    MainService.getAppt<TBody, TResponse>(
      coverKey,
      authorization,
      apiBody,
      apiType
    ),
    [coverKey, authorization, apiBody]
  );

  const [columns, setColumns] = useDynamicColumns<TColumns>(
    coverKey,
    authorization,
    apiType,
    (res as TResponse & { displayedColumns: TColumns[] })?.displayedColumns
  );

  const FiltersProps = {
    dates,
    setDate,
    columns,
    setColumns,
    columnsDefine: apiType,
  };

  return {
    dates,
    setDate,
    setDateState: setDates,
    locations,
    providers,
    errorFilters: errorProv || errorLoc,
    loadingFilters: loadingLoc || loadingProv,
    apptData: res,
    loadingData: loadingRes,
    errorData: errorRes,
    columns,
    setColumns,
    FiltersProps,
  };
};
